import { ApiUserStatus } from 'services/api/users/types';

export const statusNames: Record<number, string> = {
	[ApiUserStatus.Unverifed]: 'Unverified',
	[ApiUserStatus.Verifed]: 'Approved',
	[ApiUserStatus.Pending]: 'Pending',
	[ApiUserStatus.Rejected]: 'Rejected',
};

export const statusClassNames: Record<number, string> = {
	[ApiUserStatus.Unverifed]: 'red-bg',
	[ApiUserStatus.Verifed]: 'green-bg',
	[ApiUserStatus.Pending]: 'yellow-bg',
	[ApiUserStatus.Rejected]: 'red-bg',
};
