import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import { IPopUpData } from 'redux/reducers/popUp/types';
import { IPopUp } from '../types';

const SuccessNewMemberPopUp: FC<IPopUp & IPopUpData> = ({ open, closeModal, title, message }) => {
	return (
		<Popup open={open} closeOnDocumentClick onClose={closeModal}>
			<div className="popup">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<button type="button" className="popup__close-btn" onClick={closeModal} />
				<div className="popup-header popup-header--mb-20">
					<p className="popup-header__title">{title || 'Successful'}</p>
				</div>
				<div className="popup-img popup-img_center popup-icon--type2">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="58"
						height="58"
						viewBox="0 0 58 58"
						fill="none"
					>
						<path
							d="M29 54C42.7591 54 54 42.7591 54 29C54 15.2409 42.7591 4 29 4C15.2409 4 4 15.2409 4 29C4 42.7591 15.2409 54 29 54Z"
							fill="#007F00"
							stroke="#F0F7F0"
							strokeWidth="8"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M35.2498 27.1875H22.7498C22.2896 27.1875 21.9165 27.5606 21.9165 28.0208V35.5208C21.9165 35.9811 22.2896 36.3542 22.7498 36.3542H35.2498C35.7101 36.3542 36.0832 35.9811 36.0832 35.5208V28.0208C36.0832 27.5606 35.7101 27.1875 35.2498 27.1875Z"
							stroke="white"
							strokeWidth="1.5"
							strokeLinejoin="round"
						/>
						<path
							d="M24.8335 27.1641V23.8327C24.8313 21.6933 26.468 19.9004 28.6193 19.6854C30.7706 19.4704 32.7365 20.9033 33.1668 22.9999"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M29 30.5V33"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
				{!!message && (
					<div className="popup-text">
						<p>{message}</p>
					</div>
				)}
				{/* <div className="popup-text popup-text--type2 popup-text--center"> */}
				{/*	<p>The user fee has been changed</p> */}
				{/* </div> */}
				<div className="popup-footer">
					<div className="popup-submit">
						<button onClick={closeModal} type="button" className="btn btn-primary btn--full">
							Done
						</button>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default SuccessNewMemberPopUp;
