/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { ChangeEvent, FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { useFormatDateTime } from 'hooks/useFormatDateTime';
import {
	EApiFiatTransactionStatus,
	EApiPooledDepositTransactionStatus,
	EApiTransactionStatus,
	EPendingWithdrawalType,
} from 'services/api/transactions/types';
import {
	putFiatTransactionsRequest,
	putPendingDepositApproveRequest,
	putPendingDepositRejectRequest,
	putPendingWithdrawalNoteRequest,
} from 'redux/reducers/transactions/reducer';
import { IHistoryFiltersOptionStringValue } from 'components/AccountDetails/TransactionHistoryTab/CryptoHistory/types';
import { CheckIcon, CloseIcon, PencilIcon } from 'assets/inline-svg';
import TableItemSelect from 'ui/TableItemSelect/TableItemSelect';
import { roundingNumber } from 'services/utils/roundingNumber';
import { shortNotation } from 'services/utils/strings';
import CopyToClipboard from 'react-copy-to-clipboard';
import { notificationContainer } from 'services/utils/notificationContainer';

const STATUS_TO_EDIT_CRYPTO = [
	{
		id: 1,
		name: 'Completed',
		value: EApiPooledDepositTransactionStatus.COMPLETED,
	},
	{
		id: 2,
		name: 'Rejected',
		value: EApiPooledDepositTransactionStatus.REJECTED,
	},
];
const STATUS_TO_EDIT_FIAT = [
	{
		id: 1,
		name: 'Completed',
		value: EApiFiatTransactionStatus.COMPLETED,
	},
	{
		id: 2,
		name: 'Rejected',
		value: EApiFiatTransactionStatus.REJECTED,
	},
	{
		id: 3,
		name: 'Cancelled',
		value: EApiFiatTransactionStatus.CANCELED,
	},
];

export interface IPendingDepositsTableRow {
	id: number;
	status: {
		id: number;
		status: any;
		created_at: string;
		updated_at: string;
	};
	created_at: string;
	asset: {
		id: number;
		code: string;
		type: string;
		depositable: number;
		withdrawable: number;
		exchangeable: number;
		depositable_message: string | null;
		withdrawable_message: string | null;
		has_tag: number;
		decimals: string;
		name: string;
		deposit_max: number;
		deposit_min: number;
		withdraw_max: number;
		withdraw_min: number;
		exchange_min: number;
		img_path: string;
	};
	amount: number;
	type: string | null;
	assetType: any;
	permission: any;
	client_notes: string | null;
	admin_id: number;
	user_id: number;
	tx_hash: string;
	net_fee: number;
	gross_fee: number;
	fee: number;
	address: any;
	destination: string | null;
}

const PendingDepositsTableRow: FC<any> = ({
	id,
	status,
	created_at,
	asset,
	amount,
	type,
	// user,
	permission,
	client_notes,
	assetType,
	// admin,
	tx_hash,
	ext_tx_hash,
	admin_id,
	user_id,
	net_fee,
	gross_fee,
	fee,
	address,
	destination,
	transactions_fiats,
	transactions_cryptos,
}) => {
	const dispatch = useDispatch();
	const [dateNow] = useFormatDateTime(new Date().toISOString());
	const [date, time] = useFormatDateTime(created_at);
	const { path } = useRouteMatch();

	const statusToEditArray =
		assetType === EPendingWithdrawalType.FIAT ? STATUS_TO_EDIT_FIAT : STATUS_TO_EDIT_CRYPTO;

	const filterStatusArrayToEdit = (currentStatus: string) => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return statusToEditArray.filter((el) => el.value !== currentStatus);
	};
	const findEditedStatus = (currentStatus: string) => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return statusToEditArray.find((el) => el.value === currentStatus) || statusToEditArray[0];
	};

	const [editedGross, setEditedGross] = useState<number | undefined>(gross_fee);
	const handleEditGross = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEditedGross(Number(event.target.value));
	};
	const [editedFee, setEditedFee] = useState<number | undefined>(fee);
	const handleEditFee = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEditedFee(Number(event.target.value));
	};

	const [editedNote, setEditedNote] = useState(client_notes);
	const handleChangeEditedNote = (event: ChangeEvent<HTMLInputElement>) => {
		setEditedNote(event.target.value || null);
	};

	const defaultStatus = {
		id: 0,
		value:
			assetType === EPendingWithdrawalType.CRYPTO
				? EApiPooledDepositTransactionStatus.ADMIN_PENDING
				: 'pending',
		name: 'Pending',
	};

	const [editedStatus, setEditedStatus] = useState<IHistoryFiltersOptionStringValue>(defaultStatus);
	const [statusArrayToEdit, setStatusArrayToEdit] = useState<IHistoryFiltersOptionStringValue[]>(
		filterStatusArrayToEdit(status.status),
	);
	const handleEditStatus = (activeElement: IHistoryFiltersOptionStringValue) => {
		setEditedStatus(activeElement);
		setStatusArrayToEdit(filterStatusArrayToEdit(activeElement.value));
	};

	const [editMode, setEditMode] = useState(false);
	const enableEditMode = () => {
		setEditMode(true);
	};
	const disableEditMode = () => {
		setEditedFee(fee);
		setEditedGross(gross_fee);
		setEditMode(false);
	};

	const handleEdit = () => {
		if (assetType === EPendingWithdrawalType.CRYPTO) {
			const payload = {
				id,
				type: assetType,
				gross_fee: editedGross,
				fee: Number(editedFee),
			};
			if (editedStatus.value === 'completed') {
				dispatch(putPendingDepositApproveRequest(payload));
			} else {
				dispatch(putPendingDepositRejectRequest(payload));
			}
		} else {
			dispatch(
				putFiatTransactionsRequest({
					data: {
						date: dateNow,
						fee: Number(editedFee),
						indicated_amount: editedGross,
						status: editedStatus.value,
						note: editedNote || '',
						admin_requisite_id: transactions_fiats?.admin_requisite?.id,
					},
					type: assetType,
					id,
				}),
			);
		}
		disableEditMode();
	};
	const handleDisableEditMode = () => {
		// setEditedStatus(findEditedStatus(status.status));
		setEditedStatus(defaultStatus);
		setStatusArrayToEdit(statusToEditArray);
		disableEditMode();
	};

	const convertStatusValue = (currentStatus: EApiTransactionStatus) => {
		switch (currentStatus) {
			case EApiTransactionStatus.FAILED:
				return 'Failed';
			case EApiTransactionStatus.CANCELED:
				return 'Cancelled';
			case EApiTransactionStatus.REJECTED:
				return 'Rejected';
			case EApiTransactionStatus.ADMIN_PENDING:
				return 'Pending';
			case EApiTransactionStatus.PENDING_SIGNING:
				return 'Pending Signing';
			case EApiTransactionStatus.SUCCESS:
				return 'Completed';
			case EApiTransactionStatus.COMPLETED:
				return 'Completed';
			default:
				return `${currentStatus[0].toUpperCase()}${currentStatus.slice(1)}`;
		}
	};
	// const [full, from, to] = type.match(/From\s+(.*?)\s+To\s+(.*)/);

	const handleCopy = () => {
		notificationContainer('Сopied to clipboard.', 'info');
	};
	// const [full, from, to] = type.match(/From\s+(.*?)\s+To\s+(.*)/);
	return (
		<div className={`tr ${editMode ? 'tr--editable' : ''}`}>
			<div className="td">
				<p className="td-hidden-name">Timestamp</p>
				<p className="td-name--flex">{date}</p>
				<span className="td-more-info">{time.replaceAll('-', ':')}</span>
			</div>

			<div className="td">
				<p className="td-hidden-name">User ID</p>
				<p>{assetType === EPendingWithdrawalType.FIAT ? user_id : user_id || '-'}</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">Currency</p>
				<p className="td-name--flex">{asset.code.toUpperCase()}</p>
				<span className="td-more-info">{asset?.name}</span>
			</div>

			<div className="td">
				<p className="td-hidden-name">Gross</p>
				{editMode ? (
					<input
						type="number"
						placeholder="0"
						name="indicated_amount"
						value={editedGross || ''}
						onChange={handleEditGross}
						className="input-item input--table-item input-item---fullWidth input-item--type-td"
					/>
				) : (
					<>
						<p>{roundingNumber(gross_fee, asset.code)}</p>
						<span className="td-more-info">{asset.code.toUpperCase()}</span>
					</>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Fee</p>
				{editMode ? (
					<>
						<input
							type="number"
							placeholder="0"
							name="fee"
							value={editedFee || ''}
							onChange={handleEditFee}
							className="input-item input--table-item input-item---fullWidth input-item--type-td"
						/>
					</>
				) : (
					<>
						<p>{roundingNumber(fee, asset.code)}</p>
						<span className="td-more-info">{asset.code.toUpperCase()}</span>
					</>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Net</p>
				<p>{roundingNumber(net_fee, asset.code)}</p>
				<span className="td-more-info">{asset.code.toUpperCase()}</span>
			</div>

			<div className="td">
				<p className="td-hidden-name">Destination</p>
				{assetType === EPendingWithdrawalType.CRYPTO ? (
					<p className="copy-button">
						{destination && (
							<>
								<span className="copy-button__text copy-button__text--max-width">
									{shortNotation(destination, 4, 4) || '-'}
								</span>
								<CopyToClipboard text={destination.toString()} onCopy={handleCopy}>
									<button type="button">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="15"
											height="14"
											viewBox="0 0 15 14"
											fill="none"
										>
											<path
												d="M2.92578 12.8151C2.69245 12.8151 2.48828 12.7276 2.31328 12.5526C2.13828 12.3776 2.05078 12.1734 2.05078 11.9401V3.14635H2.92578V11.9401H9.83828V12.8151H2.92578ZM4.67578 11.0651C4.44245 11.0651 4.23828 10.9776 4.06328 10.8026C3.88828 10.6276 3.80078 10.4234 3.80078 10.1901V2.02344C3.80078 1.7901 3.88828 1.58594 4.06328 1.41094C4.23828 1.23594 4.44245 1.14844 4.67578 1.14844H11.0924C11.3258 1.14844 11.5299 1.23594 11.7049 1.41094C11.8799 1.58594 11.9674 1.7901 11.9674 2.02344V10.1901C11.9674 10.4234 11.8799 10.6276 11.7049 10.8026C11.5299 10.9776 11.3258 11.0651 11.0924 11.0651H4.67578ZM4.67578 10.1901H11.0924V2.02344H4.67578V10.1901Z"
												fill="#777E90"
											/>
										</svg>
									</button>
								</CopyToClipboard>
							</>
						)}
						{!destination && transactions_cryptos && transactions_cryptos.address?.address && (
							<>
								<span className="copy-button__text copy-button__text--max-width">
									{shortNotation(transactions_cryptos.address?.address, 4, 4) || '-'}
								</span>
								<CopyToClipboard
									text={transactions_cryptos.address?.address.toString()}
									onCopy={handleCopy}
								>
									<button type="button">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="15"
											height="14"
											viewBox="0 0 15 14"
											fill="none"
										>
											<path
												d="M2.92578 12.8151C2.69245 12.8151 2.48828 12.7276 2.31328 12.5526C2.13828 12.3776 2.05078 12.1734 2.05078 11.9401V3.14635H2.92578V11.9401H9.83828V12.8151H2.92578ZM4.67578 11.0651C4.44245 11.0651 4.23828 10.9776 4.06328 10.8026C3.88828 10.6276 3.80078 10.4234 3.80078 10.1901V2.02344C3.80078 1.7901 3.88828 1.58594 4.06328 1.41094C4.23828 1.23594 4.44245 1.14844 4.67578 1.14844H11.0924C11.3258 1.14844 11.5299 1.23594 11.7049 1.41094C11.8799 1.58594 11.9674 1.7901 11.9674 2.02344V10.1901C11.9674 10.4234 11.8799 10.6276 11.7049 10.8026C11.5299 10.9776 11.3258 11.0651 11.0924 11.0651H4.67578ZM4.67578 10.1901H11.0924V2.02344H4.67578V10.1901Z"
												fill="#777E90"
											/>
										</svg>
									</button>
								</CopyToClipboard>
							</>
						)}
						{!destination && !transactions_cryptos && (
							<span className="copy-button__text copy-button__text--max-width">-</span>
						)}
					</p>
				) : (
					<>
						{transactions_fiats && transactions_fiats.admin_requisite && (
							<>
								<span className="tooltip-wrap">
									<p>{transactions_fiats.admin_requisite?.bank_name || '-'}</p>

									{transactions_fiats.admin_requisite?.bank_name && (
										<span className="tooltip-item">
											<span className="tooltip-item__title">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="15"
													height="14"
													viewBox="0 0 15 14"
													fill="none"
												>
													<path
														d="M7.20003 12.8307C8.81085 12.8307 10.2692 12.1778 11.3248 11.1222C12.3804 10.0665 13.0334 8.60821 13.0334 6.9974C13.0334 5.38658 12.3804 3.92825 11.3248 2.8726C10.2692 1.81698 8.81085 1.16406 7.20003 1.16406C5.58922 1.16406 4.13088 1.81698 3.07524 2.8726C2.01962 3.92825 1.3667 5.38658 1.3667 6.9974C1.3667 8.60821 2.01962 10.0665 3.07524 11.1222C4.13088 12.1778 5.58922 12.8307 7.20003 12.8307Z"
														stroke="#0071AB"
														strokeLinejoin="round"
													/>
													<path
														d="M7.20036 3.71094C7.32692 3.71094 7.42952 3.81354 7.42952 3.9401C7.42952 4.06667 7.32692 4.16927 7.20036 4.16927C7.0738 4.16927 6.97119 4.06667 6.97119 3.9401C6.97119 3.81354 7.0738 3.71094 7.20036 3.71094Z"
														fill="#0071AB"
														stroke="#0071AB"
													/>
													<path
														d="M7.34603 9.91927V5.83594H7.05436H6.7627"
														stroke="#0071AB"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
													<path
														d="M6.3252 9.91406H8.36686"
														stroke="#0071AB"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											</span>
											<span className="tooltip tooltip--width-720 tooltip--bottom">
												<span className="bank-info">
													<span className="bank-info bank-info--title">
														{transactions_fiats.admin_requisite?.bank_name}
													</span>
													<span className="bank-info bank-info--list-wrapper">
														<span className="bank-info bank-info--list">
															<span className="bank-info bank-info--item">
																<span>Beneficiary Name</span>
																<p>{transactions_fiats.admin_requisite?.beneficiary_name}</p>
															</span>
															<span className="bank-info bank-info--item">
																<span>Beneficiary Address</span>
																<p>{transactions_fiats.admin_requisite?.beneficiary_address}</p>
															</span>
															<span className="bank-info bank-info--item">
																<span>Beneficiary Country</span>
																<p>
																	{transactions_fiats.admin_requisite?.beneficiary_country || '-'}
																</p>
															</span>
															<span className="bank-info bank-info--item">
																<span>Bank Name</span>
																<p>{transactions_fiats.admin_requisite?.bank_name}</p>
															</span>
														</span>
														<span className="bank-info-list bank-info--list">
															<span className="bank-info  bank-info--item">
																<span>Bank Address</span>
																<p>{transactions_fiats.admin_requisite?.bank_address}</p>
															</span>
															<span className="bank-info  bank-info--item">
																<span>Bank Country</span>
																<p>{transactions_fiats.admin_requisite?.bank_country || '-'}</p>
															</span>
															<span className="bank-info  bank-info--item">
																<span>SWIFT / BIC</span>
																<p>{transactions_fiats.admin_requisite?.swift_bic}</p>
															</span>
															<span className="bank-info  bank-info--item">
																<span>Account Number</span>
																<p>{transactions_fiats.admin_requisite?.account_number}</p>
															</span>
														</span>
														<span className="bank-info  bank-info--text">
															<span>Notes</span>
															<p>{transactions_fiats.admin_requisite?.note}</p>
														</span>
													</span>
												</span>
											</span>
										</span>
									)}
								</span>
								<span className="td-more-info">
									{transactions_fiats.admin_requisite?.account_number}
								</span>
							</>
						)}
					</>
				)}
			</div>

			{/* {assetType === EPendingWithdrawalType.CRYPTO && (
				<div className="td">
					<p className="td-hidden-name">Type</p>
					<p
						className={
							assetType === EPendingWithdrawalType.CRYPTO ? 'td--width-150' : 'td--width-130'
						}
					>
						From <span className="td-bold-text">{from}</span>
					</p>
					<p
						className={
							assetType === EPendingWithdrawalType.CRYPTO ? 'td--width-150' : 'td--width-130'
						}
					>
						To <span className="td-bold-text">{to}</span>
					</p>
				</div>
			)} */}

			{assetType === EPendingWithdrawalType.CRYPTO && (
				<>
					<p className="td-hidden-name">Tx Hash</p>
					<p className="copy-button">
						<span className="copy-button__text copy-button__text--max-width">
							{shortNotation(ext_tx_hash, 4, 4) || '-'}
						</span>
						{!!ext_tx_hash && (
							<CopyToClipboard text={ext_tx_hash.toString()} onCopy={handleCopy}>
								<button type="button">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="15"
										height="14"
										viewBox="0 0 15 14"
										fill="none"
									>
										<path
											d="M3.29199 12.8151C3.05866 12.8151 2.85449 12.7276 2.67949 12.5526C2.50449 12.3776 2.41699 12.1734 2.41699 11.9401V3.14635H3.29199V11.9401H10.2045V12.8151H3.29199ZM5.04199 11.0651C4.80866 11.0651 4.60449 10.9776 4.42949 10.8026C4.25449 10.6276 4.16699 10.4234 4.16699 10.1901V2.02344C4.16699 1.7901 4.25449 1.58594 4.42949 1.41094C4.60449 1.23594 4.80866 1.14844 5.04199 1.14844H11.4587C11.692 1.14844 11.8962 1.23594 12.0712 1.41094C12.2462 1.58594 12.3337 1.7901 12.3337 2.02344V10.1901C12.3337 10.4234 12.2462 10.6276 12.0712 10.8026C11.8962 10.9776 11.692 11.0651 11.4587 11.0651H5.04199ZM5.04199 10.1901H11.4587V2.02344H5.04199V10.1901Z"
											fill="#777E90"
										/>
									</svg>
								</button>
							</CopyToClipboard>
						)}
					</p>
				</>
			)}

			<div className="td">
				<p className="td-hidden-name">Tx ID</p>
				{assetType === EPendingWithdrawalType.CRYPTO ? (
					<>
						{user_id && id ? (
							<Link
								to={{
									// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									pathname: `${path}/${id}`,
									state: { fromPendingDepositCrypto: true, txId: id, userId: user_id },
								}}
							>
								{id}
							</Link>
						) : (
							<p>{id}</p>
						)}
					</>
				) : (
					<Link
						to={{
							// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
							pathname: `${path}/${id}`,
							state: { fromPendingDepositFiat: true, txId: id, userId: user_id },
						}}
					>
						{id}
					</Link>
				)}
			</div>

			{/* <div className="td">
				<p className="td-hidden-name">Admin Notes</p>
				{editMode ? (
					<div className="input">
						<div className="input-wrapper">
							<input
								type="text"
								placeholder="Notes"
								value={editedNote || ''}
								onChange={handleChangeEditedNote}
								className="input-item input-item--type-td"
							/>
						</div>
					</div>
				) : (
					<div className="tooltip-item-note">
						<p className="tooltip-item-text">{note ? `${note?.slice(0, 10) as string}...` : '-'}</p>
						<div className="tooltip-note">
							<p>{note || '-'}</p>
							<i />
						</div>
					</div>
				)}
			</div> */}

			{assetType === EPendingWithdrawalType.CRYPTO ? (
				<div className="td">
					<p className="td-hidden-name">Status</p>
					{editMode && status.status === EApiTransactionStatus.ADMIN_PENDING ? (
						<TableItemSelect
							activeElement={editedStatus}
							selectArray={statusArrayToEdit}
							onChange={handleEditStatus}
						/>
					) : (
						<span
							className={
								status.status === EApiTransactionStatus.ADMIN_PENDING
									? 'pending-status'
									: `${String(status.status)}-status`
							}
						>
							{convertStatusValue(status.status)}
						</span>
					)}
				</div>
			) : (
				<div className="td">
					<p className="td-hidden-name">Status</p>
					{editMode && status.status === 'pending' ? (
						<TableItemSelect
							activeElement={editedStatus}
							selectArray={statusArrayToEdit}
							onChange={handleEditStatus}
						/>
					) : (
						<span
							className={
								status.status === EApiTransactionStatus.ADMIN_PENDING
									? 'pending-status'
									: `${String(status.status)}-status`
							}
						>
							{convertStatusValue(status.status)}
						</span>
					)}
				</div>
			)}

			<div className="td td--right">
				<p className="td-hidden-name">Action</p>
				{permission && editMode && (
					<div className="table-buttons flex-e">
						<button
							type="button"
							className="btn--icon-danger btn--cancel"
							onClick={handleDisableEditMode}
						/>
						{assetType === EPendingWithdrawalType.CRYPTO ? (
							<button
								type="button"
								className="btn--icon-success btn--check"
								onClick={handleEdit}
								disabled={
									editedStatus.value === EApiPooledDepositTransactionStatus.ADMIN_PENDING &&
									client_notes === editedNote
								}
							/>
						) : (
							<button
								type="button"
								className="btn--icon-success btn--check"
								onClick={handleEdit}
								disabled={editedStatus.value === 'pending' && client_notes === editedNote}
							/>
						)}
					</div>
				)}
				{permission && !editMode && (
					<>
						{assetType === EPendingWithdrawalType.CRYPTO ? (
							// eslint-disable-next-line jsx-a11y/control-has-associated-label, react/self-closing-comp
							<button
								type="button"
								className="btn btn--icon btn--edit"
								onClick={enableEditMode}
								disabled={status.status !== EApiTransactionStatus.ADMIN_PENDING}
							></button>
						) : (
							// eslint-disable-next-line jsx-a11y/control-has-associated-label, react/self-closing-comp
							<button
								type="button"
								className="btn btn--icon btn--edit"
								onClick={enableEditMode}
								disabled={status.status !== 'pending'}
							></button>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default PendingDepositsTableRow;
