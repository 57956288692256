import React, { FC } from 'react';
import Popup from 'reactjs-popup';

interface IStatusApproved {
	open: boolean;
	closeModal: () => void;
	onConfirm: () => void;
	firstName: string | null;
	lastName: string | null;
	email: string | null;
}

const StatusApproved: FC<IStatusApproved> = ({
	open,
	closeModal,
	onConfirm,
	firstName,
	lastName,
	email,
}) => {
	return (
		<Popup open={open} onClose={closeModal} lockScroll>
			<div className="popup popup--width-480">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<button type="button" className="popup__close-btn" onClick={closeModal} />
				<div className="popup-header popup-header--mb-20">
					<p className="popup-header__title">Application approval confirmation</p>
				</div>
				<div className="popup-body">
					<div className="popup-icon popup-icon--status">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="58"
							height="58"
							viewBox="0 0 58 58"
							fill="none"
						>
							<path
								d="M29.0052 49.8307C40.4635 49.8307 49.8385 40.4557 49.8385 28.9974C49.8385 17.5391 40.4635 8.16406 29.0052 8.16406C17.5469 8.16406 8.17188 17.5391 8.17188 28.9974C8.17188 40.4557 17.5469 49.8307 29.0052 49.8307Z"
								fill="#007F00"
								stroke="#007F00"
								strokeOpacity="0.06"
								strokeWidth="16"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path d="M21.0469 28.6171L26.3437 33.9139L36.956 23.3203" fill="#007F00" />
							<path
								d="M21.0469 28.6171L26.3437 33.9139L36.956 23.3203"
								stroke="white"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</div>
					<div className="popup-text popup-text--type2 popup-text--center">
						<p>You’re about to approve the following user application:</p>
						<p>
							{firstName} {lastName}
						</p>
						<p>{email}</p>
					</div>
				</div>
				<div className="popup-footer">
					<div className="popup-submit popup-submit--column">
						<button type="button" className="btn btn-primary btn--full" onClick={onConfirm}>
							Approve
						</button>
						<button type="button" className="btn btn-danger btn--full" onClick={closeModal}>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</Popup>
	);
};
export default StatusApproved;
