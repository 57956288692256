import { call, put, takeEvery } from 'redux-saga/effects';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { notificationContainer } from 'services/utils/notificationContainer';
import { PayloadAction } from '@reduxjs/toolkit';
import {
	getFeeCurrenciesRequest,
	getFeeCurrenciesSuccess,
	getFeeFiatRequest,
	getFeeFiatSuccess,
	getFeeRequest,
	getFeeSpreadManagementRequest,
	getFeeSpreadManagementSuccess,
	getFeeSuccess,
	putFeeCurrenciesRequest,
	putFeeCurrenciesSuccess,
	putFeeRequest,
	putFeeSpreadManagementRequest,
	putFeeSuccess,
	updateState,
} from './reducer';
import { TApiGetFiatHistoryResponse } from '../../../services/api/transactions/types';
import { api } from '../../../services';
import { notificationsMessagesInfo } from '../../../services/utils/notificationsMessages/notificationsMessagesInfo';
import { IWalletAddress, IWalletAddressRequestPayload } from '../walletAddresses/types';

// =============================================================:

// =============================================================:
function* getFeeRequestWorker({ payload }: PayloadAction<any>) {
	try {
		const data: TApiGetFiatHistoryResponse = yield call(api.fee.getFee, payload);
		yield put(showLoading());
		yield put(getFeeSuccess(data));
	} catch (error) {
		// notificationContainer('Fee were not found', 'error');
	} finally {
		yield put(hideLoading());
	}
}
function* getFeeFiatRequestWorker({ payload }: PayloadAction<any>) {
	try {
		const data: TApiGetFiatHistoryResponse = yield call(api.fee.getFee, payload);
		yield put(showLoading());
		yield put(getFeeFiatSuccess(data));
	} catch (error) {
		// notificationContainer('Fee were not found', 'error');
	} finally {
		yield put(hideLoading());
	}
}
function* putFeeRequestWorker({
	payload,
}: PayloadAction<{ data: IWalletAddressRequestPayload; feeType: string }>) {
	try {
		yield call(api.fee.putFee, payload.data);
		yield put(showLoading());
		yield put(putFeeSuccess());
		notificationContainer(
			notificationsMessagesInfo.putFeeRequest(payload.feeType),
			'success',
			'Changes saved',
		);
		yield put(updateState());
	} catch (error) {
		// notificationContainer('Fee were not found', 'error');
	} finally {
		yield put(hideLoading());
	}
}

function* getFeeSpreadManagementRequestWorker({ payload }: PayloadAction<any>) {
	try {
		const data: TApiGetFiatHistoryResponse = yield call(api.fee.getSpread, payload);
		yield put(showLoading());
		yield put(getFeeSpreadManagementSuccess(data));
	} catch (error) {
		// notificationContainer('Fee were not found', 'error');
	} finally {
		yield put(hideLoading());
	}
}

function* putFeeSpreadManagementRequestWorker(data: PayloadAction<any>) {
	const { payload } = data;
	try {
		yield call(api.fee.putSpread, payload);
		yield put(showLoading());
		yield put(putFeeSuccess());
		notificationContainer(
			notificationsMessagesInfo.putFeeSpreadManagementRequest,
			'success',
			'Changes saved',
		);
		yield put(updateState());
	} catch (error) {
		// notificationContainer('Fee were not found', 'error');
	} finally {
		yield put(hideLoading());
	}
}

function* getFeeCurrenciesRequestWorker({ payload }: PayloadAction<any>) {
	try {
		const data: TApiGetFiatHistoryResponse = yield call(api.fee.getCurrencies, payload);
		yield put(showLoading());
		yield put(getFeeCurrenciesSuccess(data));
	} catch (error) {
		// notificationContainer('Fee were not found', 'error');
	} finally {
		yield put(hideLoading());
	}
}
function* putFeeCurrenciesRequestWorker({
	payload,
}: PayloadAction<{ data: any; type: string; assetName: string; action: string }>) {
	try {
		yield call(api.fee.putCurrencies, payload.data);
		yield put(showLoading());
		yield put(putFeeCurrenciesSuccess());
		const data: TApiGetFiatHistoryResponse = yield call(api.fee.getCurrencies, {
			type: payload.type,
		});
		yield put(getFeeCurrenciesSuccess(data));
		notificationContainer(
			notificationsMessagesInfo.putFeeCurrenciesRequest(payload.assetName, payload.action),
			'success',
			'Changes saved',
		);
	} catch (error) {
		// notificationContainer('Fee were not found', 'error');
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:

// =============================================================:
export function* feeSaga() {
	yield takeEvery(getFeeRequest.type, getFeeRequestWorker);
	yield takeEvery(getFeeFiatRequest.type, getFeeFiatRequestWorker);
	yield takeEvery(putFeeRequest.type, putFeeRequestWorker);
	yield takeEvery(getFeeSpreadManagementRequest.type, getFeeSpreadManagementRequestWorker);
	yield takeEvery(putFeeSpreadManagementRequest.type, putFeeSpreadManagementRequestWorker);
	yield takeEvery(getFeeCurrenciesRequest.type, getFeeCurrenciesRequestWorker);
	yield takeEvery(putFeeCurrenciesRequest.type, putFeeCurrenciesRequestWorker);
}
