/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import IconSvg from 'ui/Svg/IconSvg';
import { deleteWalletAddressRequest } from 'redux/reducers/walletAddresses/reducer';

import { setPopUpData } from 'redux/reducers/popUp/reducer';

import { IWalletAddressRequestPayload } from 'redux/reducers/walletAddresses/types';
import {
	getFeeFiatRequest,
	getFeeRequest,
	putFeeRequest,
	updateFee,
	updateFiatFee,
} from 'redux/reducers/fee/reducer';
import { getFeeFiatStoreData, getFeeStoreData, updateState } from 'redux/reducers/fee/selectors';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import TradeFeeFiatFiatItem from './TradeItem/TradeFeeFiatFiatItem';
import PerPageDropdown from '../../../services/utils/PaginationPerPageDropdown';
import FiatItem from '../DepositFee/Fiat/FiatItem/FiatItem';
import TableBodyNoData from '../../../ui/TableBodyNoData/TableBodyNoData';
import Loading from '../../../layouts-elements/Loading/Loading';

const TradeFeeFiatFiat = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const dispatch = useDispatch();
	const fee = useSelector(getFeeFiatStoreData);
	const { loading } = useSelector(getFeeStoreData);
	console.log(fee);
	const updateStateReqvest = useSelector(updateState);
	const permissions = useSelector(getPermissions);
	const perPage = 10;
	const [itemsPerPage, setItemsPerPage] = useState(perPage);
	const totalPage = fee?.dataFiat?.total;
	useLayoutEffect(() => {
		let data;
		if (itemsPerPage !== 10) {
			data = {
				// per_page: itemsPerPage,
				// current_page: 1,
				type: 'fiat',
			};
			setCurrentPage(1);
		} else {
			data = {
				// per_page: itemsPerPage,
				// current_page: currentPage,
				type: 'fiat',
			};
		}
		dispatch(getFeeFiatRequest(data));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [itemsPerPage]);
	// }, [updateStateReqvest, itemsPerPage]);
	const handlePageClick = ({ selected }: { selected: number }) => {
		setCurrentPage(selected + 1);
		const data = {
			per_page: itemsPerPage,
			current_page: selected + 1,
			type: 'fiat',
		};
		dispatch(getFeeFiatRequest(data));
	};

	const onDelete = (id: number, nickname: string) => {
		dispatch(
			setPopUpData({
				message: `Your wallet address "${nickname}" was deleted successfully.`,
			}),
		);
		dispatch(deleteWalletAddressRequest({ id }));
	};

	const deleteHandler = (id: number, nickname: string) => {
		dispatch(
			setPopUpData({
				title: 'Delete Wallet Address',
				message: 'Are you sure you what to delete?',
				confirmHandler: () => onDelete(id, nickname),
			}),
		);
	};

	const updateHandler = (obj: IWalletAddressRequestPayload) => {
		dispatch(putFeeRequest({ data: obj, feeType: 'Trade' }));
		// dispatch(updateFee({ data: obj }));
		dispatch(updateFiatFee({ data: obj }));
	};

	const setItemsPerPageHandler = (num: number | string) => {
		setItemsPerPage(Number(num));
	};

	return (
		<>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table--user-management-fee-management-withdraw-crypto fiat-fiat-td">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<p>Currency</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Fee %</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Fee Fixed</p>
									</div>
								</div>
								<div className="td td-right">
									<div className="td-name">
										<p>Action</p>
									</div>
								</div>
							</div>
						</div>

						<div className="table-body">
							{!loading && (
								<>
									{' '}
									{fee?.dataFiat?.data.length ? (
										fee?.dataFiat?.data
											.filter((e: any) => e.type === 'fiat' || e.type === 'token')
											.map((e: any) => (
												<TradeFeeFiatFiatItem
													key={e.id}
													deleteHandler={deleteHandler}
													updateHandler={updateHandler}
													data={e}
													permission={!!permissions?.[EPermissionNames.FEE_MANAGEMENT].editable}
												/>
											))
									) : (
										<TableBodyNoData />
									)}
								</>
							)}
							{loading ? <Loading /> : null}
						</div>
						{/* <div className="table-footer"> */}
						{/*	<div className="pagination-block pagination-block--full"> */}
						{/*		<PerPageDropdown */}
						{/*			options={[10, 20, 50, 100]} */}
						{/*			setPerPage={setItemsPerPageHandler} */}
						{/*			perPage={itemsPerPage} */}
						{/*		/> */}
						{/*		<ReactPaginate */}
						{/*			breakLabel="..." */}
						{/*			pageCount={Math.ceil(totalPage / itemsPerPage)} */}
						{/*			forcePage={currentPage - 1} */}
						{/*			onPageChange={handlePageClick} */}
						{/*			className="pagination" */}
						{/*			activeClassName="active" */}
						{/*			previousClassName="pagination__arrow pagination__arrow--prev" */}
						{/*			nextClassName="pagination__arrow pagination__arrow--next" */}
						{/*			previousLabel="" */}
						{/*			nextLabel="" */}
						{/*		/> */}
						{/*	</div> */}
						{/* </div> */}
					</div>
				</div>
			</div>
			{/* {totalPage > perPage && (
				<div style={{ marginBottom: '32px' }} className="pagination-block">
					<ReactPaginate
						breakLabel="..."
						pageCount={Math.ceil(totalPage / perPage)}
						onPageChange={handlePageClick}
						className="pagination"
						activeClassName="active"
						previousClassName="arrow"
						nextClassName="arrow"
						previousLabel={<IconSvg name="keyboard-arrow-left" w="10" h="16" />}
						nextLabel={<IconSvg name="keyboard-arrow-right" w="10" h="16" />}
					/>
				</div>
			)} */}
		</>
	);
};

export default TradeFeeFiatFiat;
