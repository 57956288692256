import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import {
	EApiCryptoTransactionStatus,
	EApiPooledDepositTransactionStatus,
	EApiTransactionStatus,
	IApiCryptoHistoryItem,
} from 'services/api/transactions/types';
import Input from 'ui/Formik/Input';
import InputFile from 'ui/Formik/InputFile/InputFile';
import { CloseFileIcon } from 'assets/inline-svg';
import { useLocation } from 'react-router';

import handleUploadIcon from 'ui/Formik/InputFile/config';
import { IHistoryFiltersOptionStringValue } from 'components/AccountDetails/TransactionHistoryTab/CryptoHistory/types';
import { IStateLocation } from '../types';
import {
	createCryptoDetailsRequest,
	deleteCryptoDetailsFilesRequest,
	downloadCryptoFileRequest,
} from '../../../redux/reducers/detailedView/reducer';
import capitalizeFirstLetter from '../../../utils/capitalizeFirstLetter';
import { statusTitle } from '../../AccountDetails/TransactionHistoryTab/utils';
import { TOneUserUnsafe } from '../../../redux/reducers/users/types';
import { roundingNumber } from '../../../services/utils/roundingNumber';
import DefaultFields from '../DefaultFields/DefaultFields';
import { IApiGetOneUserResponse } from '../../../services/api/users/types';

interface IDetailedViewCryptoForm {
	item: IApiCryptoHistoryItem;
	userData: IApiGetOneUserResponse;
}

const STATUS_TO_EDIT_CRYPTO = [
	{
		id: 1,
		name: 'Approved',
		value: EApiPooledDepositTransactionStatus.COMPLETED,
	},
	{
		id: 2,
		name: 'Rejected',
		value: EApiPooledDepositTransactionStatus.FAILED,
	},
];

interface ICustomInput {
	label: string;
	value: string | number | undefined;
	type: string;
	disabled: boolean;
}

const CustomInput: FC<ICustomInput> = ({ label, value, type, disabled }) => {
	return (
		<div className="input">
			<label>
				<p className="input__name">{label}</p>
				<div className="input-wrapper">
					<input type={type} className="input-item" value={value} disabled={disabled} />
				</div>
			</label>
		</div>
	);
};

const DetailedCryptoWithdrawalPendingForm: FC<IDetailedViewCryptoForm> = ({ item, userData }) => {
	const dispatch = useDispatch();
	const [fileList, setFileList] = useState<any>([]);

	const { state } = useLocation<IStateLocation>();
	const [editedStatus, setEditedStatus] = useState<IHistoryFiltersOptionStringValue>({
		id: 0,
		value: EApiPooledDepositTransactionStatus.ADMIN_PENDING,
		name: 'Pending',
	});

	const statusToEditArray = STATUS_TO_EDIT_CRYPTO;

	const filterStatusArrayToEdit = (currentStatus: EApiPooledDepositTransactionStatus | string) => {
		return statusToEditArray.filter((el) => el.value !== currentStatus);
	};

	const [statusArrayToEdit, setStatusArrayToEdit] = useState<IHistoryFiltersOptionStringValue[]>(
		filterStatusArrayToEdit(item.status),
	);

	const handleEditStatus = (activeElement: IHistoryFiltersOptionStringValue) => {
		setEditedStatus(activeElement);
		setStatusArrayToEdit(filterStatusArrayToEdit(activeElement.value));
	};

	const initialValues = {
		client_notes: item.client_notes || '',
		reference: item.reference || item.ext_tx_hash || '',
		description: item.description || item?.address.address || '',
		admin_notes: item.admin_notes || '',
		status:
			// eslint-disable-next-line no-nested-ternary
			item.status === EApiTransactionStatus.ADMIN_PENDING
				? statusTitle.pending
				: item.status === EApiTransactionStatus.PENDING_SIGNING
				? statusTitle.pending_signing
				: capitalizeFirstLetter(item.status),
	};

	const validationSchema = yup.object().shape({
		admin_notes: yup.string().max(255, 'Maximum length is 255 characters'),
		client_notes: yup.string().max(255, 'Maximum length is 255 characters'),
		reference: yup.string().max(255, 'Maximum length is 255 characters'),
		description: yup.string().max(255, 'Maximum length is 255 characters'),
	});

	const deleteFilesHandler = (
		event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
		file: string,
	) => {
		event.stopPropagation();
		const payload = {
			id: item.id,
			files: [file],
		};
		dispatch(deleteCryptoDetailsFilesRequest(payload));
		// console.log(payload);
	};

	const handleDownloadFile = (file: string) => {
		dispatch(downloadCryptoFileRequest({ file: file.split('/')[0] }));
	};

	return (
		<div className="detailed-view-form">
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={(value, { resetForm, setSubmitting }) => {
					setSubmitting(true);
					const formData = new FormData();
					fileList &&
						fileList.length &&
						fileList.forEach((file: File) => formData.append(`files[]`, file));
					const requestBody = {
						id: item.id,
						client_notes: value.client_notes,
						admin_notes: value.admin_notes,
						reference: value.reference,
						description: value.description,
					};
					Object.keys(requestBody).forEach((key) => {
						const valueRequest = requestBody[key as keyof typeof requestBody];
						if (valueRequest !== undefined) {
							formData.append(key, valueRequest.toString());
						}
					});
					dispatch(
						createCryptoDetailsRequest({
							id: item.id,
							body: formData,
						}),
					);
					setFileList([]);
					resetForm();
					setSubmitting(false);
				}}
				enableReinitialize
				validateOnBlur
			>
				{({
					setFieldTouched,
					touched,
					values,
					errors,
					isValid,
					dirty,
					setFieldValue,
					isSubmitting,
					setTouched,
				}) => (
					<>
						<Form>
							<div className="input-wrap--col-2">
								<DefaultFields userData={userData} item={item} />
								<CustomInput
									label="Currency"
									// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									value={`${item.asset?.code.toUpperCase()} ${item.asset?.name}`}
									type="text"
									disabled
								/>
								<CustomInput
									label="Gross"
									value={`${roundingNumber(
										item.gross_fee,
										item?.asset?.code,
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									)} ${item?.asset?.code.toUpperCase()}`}
									type="text"
									disabled
								/>
								<CustomInput
									label="Fee"
									value={`${roundingNumber(
										item.fee,
										item?.asset?.code,
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									)} ${item?.asset?.code.toUpperCase()}`}
									type="text"
									disabled
								/>
								<CustomInput
									label="Net"
									value={`${roundingNumber(
										item.net_fee,
										item?.asset?.code,
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									)} ${item?.asset?.code.toUpperCase()}`}
									type="text"
									disabled
								/>

								<CustomInput
									label="Destination"
									value={`${
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions, no-nested-ternary
										item.destination
											? item.destination
											: item?.address?.address
											? item.address.address
											: '-'
									}`}
									type="text"
									disabled
								/>

								<CustomInput
									label="Tx hash"
									// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									value={`${item.ext_tx_hash ? item.ext_tx_hash : '-'}`}
									type="text"
									disabled
								/>
								{!item.status.includes('pending') && (
									<CustomInput label="Tx ID" value={item.id} type="text" disabled />
								)}
								{item.status.includes('pending') && userData.type === 'corporate' && (
									<CustomInput label="Tx ID" value={item.id} type="text" disabled />
								)}
								{item.status.includes('pending') && userData.type !== 'corporate' && (
									<CustomInput label="Tx ID" value={item.id} type="text" disabled />
								)}
								<CustomInput label="Oneify ID" value={item?.user?.oneify_id} type="text" disabled />

								{/* <DetailedViewSelect
									activeElement={editedStatus}
									selectArray={statusArrayToEdit}
									onChange={handleEditStatus}
									disabled
								/> */}
								<Field title="Status" type="text" component={Input} name="status" disable />

								<Field
									title="Description"
									type="text"
									placeholder=""
									name="description"
									component={Input}
									onBlur={() => setFieldTouched('description')}
								/>
								<Field
									title="Reference"
									type="text"
									placeholder=""
									name="reference"
									component={Input}
									onBlur={() => setFieldTouched('reference')}
								/>
							</div>
							<Field
								title="Admin Notes"
								type="textarea"
								placeholder=""
								name="admin_notes"
								component={Input}
								onBlur={() => setFieldTouched('admin_notes')}
							/>
							<Field
								title="Client Notes"
								type="textarea"
								placeholder=""
								name="client_notes"
								component={Input}
								onBlur={() => setFieldTouched('client_notes')}
							/>

							<div className="input">
								<p className="input__name">Upload File</p>
								<InputFile fileList={fileList} setFileList={setFileList} />
							</div>

							<div className="input">
								<p className="input__name">Attached Files</p>
								<div className="input__links">
									{item.files?.length > 0 ? (
										item.files.map((file: string, idx: number) => {
											return (
												<div
													key={`${file}-${idx.toString()}`}
													className="input-file-preview__item"
													onClick={() => handleDownloadFile(file)}
												>
													<img src={handleUploadIcon(file.split('.')[1])} alt="" />
													<span className="input-file-preview__name" title={file}>
														{file}
													</span>
													<span
														className="input-file-preview__item__del"
														onClick={(event) => deleteFilesHandler(event, file)}
													>
														<CloseFileIcon />
													</span>
												</div>
											);
										})
									) : (
										<div> No data</div>
									)}
								</div>
							</div>

							<button
								type="submit"
								className="btn btn-primary btn--full"
								style={{ marginTop: 14 }}
								disabled={isSubmitting}
							>
								Submit
							</button>
						</Form>
					</>
				)}
			</Formik>
		</div>
	);
};

export default DetailedCryptoWithdrawalPendingForm;
