import { IReferralItem } from 'redux/reducers/referrals/types';
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGetFilePayload } from 'services/api/transactions/types';
import {
	IRequestBlocked,
	IUserArchivedRequest,
	IApiGetOneUserParams,
	IApiGetOneUserResponse,
	IUserWalletsResponse,
	IDeleteUserReferalRequest,
	ILiquidityProviderRequest,
	ITradingLimitsRequest,
	ITradingLimitsResponse,
	IUpdateTradingLimitRequest,
	IUpdateUserPayload,
	IUserReferralRequest,
	IUsersRequest,
	ISecretCodeResponse,
	IUserFireBlockIdPayload,
	ITransferLimitsRequest,
	ITransferLimitsResponse,
	IUpdateTransferLimitRequest,
} from 'services/api/users/types';
import {
	IAdminPasswordResetRequest,
	TwoFaItem,
} from '../../../components/ResetRequest/ResetRequestItem/types';

import {
	IPagination,
	IUser,
	IUsersStore,
	IRequiredEmailsResponse,
	ITogglerPayload,
	IAdminTogglerPayload,
	IUsersWithPagination,
	IUserFeesRequest,
	IUsersFeeResponse,
	IUserFeesUpdteRequestPayload,
	IUsersFeeSpreadResponse,
	IUserReferralRequestPayload,
	IUserFeesUpdteSpreadRequestPayload,
	IUserWalletsRequest,
	ICheckFireblockIdRequest,
	IUpdateBalancesPayload,
	IUserFileDownloadPayload,
} from './types';

// ==========================================:
export const initialState: IUsersStore = {
	users: null,
	loader: false,
	oneUser: null,
	oneUserLoading: false,
	userWalletsList: null,
	userReferralList: null,
	userTradingLimitsList: null,
	userTransferLimitsList: {
		data: null,
	},
	createUserLoader: false,
	createUserData: null,
	resetRequests: null,
	requiredEmails: null,
	requiredEmailsLoading: false,
	adminPasswordResetRequests: null,
	adminPasswordResetRequestsLoading: false,
	adminTwoFaResetRequests: null,
	userFees: null,
	userFeesFiat: null,
	oneUserFeesLoading: false,
	oneUserFeesFiatLoading: false,
	userFeesSpread: null,
	oneUserFeesSpreadLoading: false,
	passwordResetRequests: null,
	mailSwitcher: false,
	//
	referralSystem: null,
	referralSystemLoading: false,
	isValidFireblock: false,
	//
	setFireblockIdLoading: false,
	fileLoading: false,
};

const users = createSlice({
	name: '@@users',
	initialState,
	reducers: {
		getUsersInit: (state) => {
			const newState = state;
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			newState.users.last_page = 1;
		},
		getUsersRequest: (state, action: PayloadAction<IUsersRequest>) => {
			const newState = state;
			newState.loader = true;
		},
		getUsersSuccess: (state, action: PayloadAction<IUsersWithPagination>) => {
			const newState = state;
			newState.users = action.payload;

			newState.loader = false;
		},

		updateUserBlockStatus: (state, action: PayloadAction<IRequestBlocked>) => {
			const newState = state;
			newState.loader = true;
		},

		updateUserArchiveStatus: (state, action: PayloadAction<IUserArchivedRequest>) => {
			state.loader = true;
		},

		getOneUserRequest: (state, action: PayloadAction<IApiGetOneUserParams>) => {
			const newState = state;
			newState.oneUserLoading = true;
		},

		getOneUserSuccess: (state, { payload }: PayloadAction<IApiGetOneUserResponse>) => {
			const newState = state;
			newState.oneUser = payload;
			newState.oneUserLoading = false;
		},

		getOneUserError: (state) => {
			const newState = state;
			newState.oneUser = null;
			newState.oneUserLoading = false;
		},

		getUserWalletsRequest: (state, action: PayloadAction<IUserWalletsRequest>) => {
			const newState = state;
			newState.loader = true;
		},

		getUserWalletsSuccess: (state, { payload }: PayloadAction<IUserWalletsResponse>) => {
			const newState = state;
			newState.userWalletsList = payload;
			newState.loader = false;
		},

		getUserReferralRequest: (state, action: PayloadAction<IUserReferralRequest>) => {
			const newState = state;
			newState.loader = true;
		},

		getUserReferralSuccess: (state, { payload }: PayloadAction<IPagination<IReferralItem>>) => {
			const newState = state;
			newState.userReferralList = payload;
			newState.loader = false;
		},

		deleteUserReferralRequest: (state, action: PayloadAction<IDeleteUserReferalRequest>) => {
			state.loader = true;
		},
		// new api
		referralSystemRequest: (state, action: PayloadAction<any>) => {
			state.referralSystemLoading = true;
		},
		referralSystemSuccess: (state, { payload }: PayloadAction<any>) => {
			state.referralSystem = payload?.data;
			state.referralSystemLoading = false;
		},
		putUserLiquidityProviderRequest: (state, action: PayloadAction<ILiquidityProviderRequest>) => {
			const newState = state;
			newState.loader = true;
		},

		putUserLiquidityProviderSuccess: (state) => {
			const newState = state;
			newState.loader = false;
		},

		getUserTradingLimitsRequest: (state, action: PayloadAction<ITradingLimitsRequest>) => {
			state.loader = true;
		},
		getUserTradingLimitsSuccess: (state, { payload }: PayloadAction<ITradingLimitsResponse>) => {
			state.userTradingLimitsList = payload;
			state.loader = false;
		},

		getUserTransferLimitsRequest: (state, action: PayloadAction<ITransferLimitsRequest>) => {
			state.loader = true;
		},
		getUserTransferLimitsSuccess: (state, { payload }: PayloadAction<ITransferLimitsResponse>) => {
			state.userTransferLimitsList = payload;
			state.loader = false;
		},

		putUserTradingLimitRequest: (state, action: PayloadAction<IUpdateTradingLimitRequest>) => {
			state.loader = true;
		},

		putUserTransferLimitRequest: (state, action: PayloadAction<IUpdateTransferLimitRequest>) => {
			state.loader = true;
		},

		createUserRequest: (state, action) => {
			state.createUserLoader = true;
		},
		createUserRequestFinished: (state) => {
			state.createUserLoader = false;
		},
		setUserData: (state, { payload }: PayloadAction<any>) => {
			state.createUserData = payload;
		},
		getResetRequests: (state, action) => {
			const newState = state;
			newState.loader = true;
		},

		getResetRequestsSuccess: (state, action: PayloadAction<IPagination<TwoFaItem>>) => {
			const requestsState = state;
			const { payload } = action;
			requestsState.resetRequests = payload;
			requestsState.loader = false;
		},
		reject2FA: (state, action) => {},
		reset2FA: (state, action) => {},
		getUserRequiredEmailsRequest: (state, action) => {
			const emailsState = state;
			emailsState.requiredEmailsLoading = true;
		},
		getUserRequiredEmailsSuccess: (state, action: PayloadAction<IRequiredEmailsResponse>) => {
			const { payload } = action;
			const emailsState = state;
			emailsState.requiredEmailsLoading = false;
			emailsState.requiredEmails = payload;
		},
		putUserRequiredEmailsRequest: (state, action) => {},

		setToggler: (state, action: PayloadAction<ITogglerPayload>) => {
			const { payload } = action;
			const emailTogglersState = state;
			const oldRequiredEmailsState = JSON.parse(JSON.stringify(emailTogglersState.requiredEmails));
			emailTogglersState.requiredEmails = {
				...oldRequiredEmailsState,
				[payload.toggler]: !oldRequiredEmailsState[payload.toggler],
			};
		},
		getAdminRequiredEmailsRequest: (state, action) => {},
		getAdminRequiredEmailsSuccess: (state, action: PayloadAction<IRequiredEmailsResponse>) => {
			const { payload } = action;
			const emailsState = state;
			emailsState.requiredEmails = payload;
		},
		putAdminRequiredEmailsRequest: (state, action) => {},
		setAdminToggler: (state, action: PayloadAction<IAdminTogglerPayload>) => {
			const { payload } = action;
			const emailTogglersState = state;
			const oldRequiredEmailsState = JSON.parse(JSON.stringify(emailTogglersState.requiredEmails));
			emailTogglersState.requiredEmails = {
				...oldRequiredEmailsState,
				[payload.toggler]: !oldRequiredEmailsState[payload.toggler],
			};
		},

		updateUserDataRequest: (state, action: PayloadAction<any>) => {},
		getAdminResetDataRequest: (state, action) => {
			const newState = state;
			newState.adminPasswordResetRequestsLoading = true;
		},
		getAdminResetPasswordRequestsSuccess: (
			state,
			action: PayloadAction<IPagination<IAdminPasswordResetRequest>>,
		) => {
			const newState = state;
			newState.adminPasswordResetRequestsLoading = false;
			const { payload } = action;
			newState.adminPasswordResetRequests = payload;
		},
		getAdminResetTwoFaDataRequest: (state, action) => {
			const newState = state;
			newState.loader = true;
		},
		getAdminResetTwoFaDataSuccess: (state, action) => {
			const newState = state;
			const { payload } = action;
			newState.adminTwoFaResetRequests = payload;
			newState.loader = false;
		},
		getUserPasswordResetRequests: (state, action) => {
			const newState = state;
			newState.loader = true;
		},
		getUserPasswordRequestsSuccess: (
			state,
			action: PayloadAction<IPagination<IAdminPasswordResetRequest>>,
		) => {
			const newState = state;
			const { payload } = action;
			newState.passwordResetRequests = payload;
			newState.loader = false;
		},
		rejectAdmin2FA: (state, action) => {},
		resetAdmin2FA: (state, action) => {},
		rejectAdminPassword: (state, action) => {},
		resetAdminPassword: (state, action) => {},
		rejectUserPassword: (state, action) => {},
		resetUserPassword: (state, action) => {},
		getUserFeeRequest: (state, action: PayloadAction<IUserFeesRequest>) => {
			const newState = state;
			newState.oneUserFeesLoading = true;
		},
		getUserFeeFiatRequest: (state, action: PayloadAction<IUserFeesRequest>) => {
			const newState = state;
			newState.oneUserFeesFiatLoading = true;
		},
		getUserFeeUpdateRequest: (state, action: PayloadAction<IUserFeesRequest>) => {
			const newState = state;
			// newState.oneUserFeesLoading = true;
		},
		getUserFeeFiatUpdateRequest: (state, action: PayloadAction<IUserFeesRequest>) => {
			const newState = state;
			// newState.oneUserFeesLoading = true;
		},
		getUserFeeSuccess: (state, { payload }: PayloadAction<IUsersFeeResponse>) => {
			const newState = state;
			newState.userFees = payload;
			newState.oneUserFeesLoading = false;
		},
		getUserFeeFiatSuccess: (state, { payload }: PayloadAction<IUsersFeeResponse>) => {
			const newState = state;
			newState.userFeesFiat = payload;
			newState.oneUserFeesFiatLoading = false;
		},
		getUserFeeError: (state) => {
			const newState = state;
			newState.userFees = null;
			newState.oneUserFeesLoading = false;
		},
		putUserFeesRequest: (state, action: PayloadAction<IUserFeesUpdteRequestPayload>) => {
			const newState = state;
			// newState.oneUserFeesLoading = true;
		},
		updateUserFees: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			const updatableItemIndex = newState?.userFees?.data.findIndex(
				(item: any) => item.id === payload.asset_id,
			);
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const updatableItem = newState.userFees.data[updatableItemIndex].fee;
			const updatedItem = { ...updatableItem, ...payload.data };
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const updatedItems = [...newState.userFees.data];
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			updatedItems[updatableItemIndex].fee = updatedItem;
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			newState.userFees.data = updatedItems;
		},
		updateUserFiatFees: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			const updatableItemIndex = newState?.userFeesFiat?.data.findIndex(
				(item: any) => item.id === payload.asset_id,
			);
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const updatableItem = newState.userFeesFiat.data[updatableItemIndex].fee;
			const updatedItem = { ...updatableItem, ...payload.data };
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const updatedItems = [...newState.userFeesFiat.data];
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			updatedItems[updatableItemIndex].fee = updatedItem;
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			newState.userFeesFiat.data = updatedItems;
		},

		getUserSpreadFeeRequest: (state, action: PayloadAction<IUserFeesRequest>) => {
			const newState = state;
			newState.oneUserFeesSpreadLoading = true;
		},
		getUserFeeSpreadSuccess: (state, { payload }: PayloadAction<IUsersFeeSpreadResponse>) => {
			const newState = state;
			newState.userFeesSpread = payload;
			newState.oneUserFeesSpreadLoading = false;
		},

		getUserFeeSpreadError: (state) => {
			const newState = state;
			newState.userFeesSpread = null;
			newState.oneUserFeesSpreadLoading = false;
		},
		updateUserSpreadFeesRequest: (state, action: PayloadAction<IUserFeesRequest>) => {
			const newState = state;
			// newState.oneUserFeesSpreadLoading = true;
		},
		putUserSpreadFeesRequest: (
			state,
			action: PayloadAction<IUserFeesUpdteSpreadRequestPayload>,
		) => {
			const newState = state;
			// newState.oneUserFeesSpreadLoading = true;
		},
		updateUserSpreadFees: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			const updatableItemIndex = newState?.userFeesSpread?.data.findIndex(
				(item: any) => item.id === payload.spread_id,
			);
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const updatableItem = newState.userFeesSpread.data[updatableItemIndex];
			const updatedItem = { ...updatableItem, ...payload.data };
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const updatedItems = [...newState.userFeesSpread.data];
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			updatedItems[updatableItemIndex] = updatedItem;
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			newState.userFeesSpread.data = updatedItems;
		},
		setUserStatus: (state, action) => {},
		setUserStatusSuccess: (state, { payload }: PayloadAction<IApiGetOneUserResponse>) => {
			if (state.oneUser) {
				state.oneUser.status_id = payload.status_id;
			}
		},
		setFireblockIdRequest: (state, action: PayloadAction<IUserFireBlockIdPayload>) => {
			const newState = state;
			newState.setFireblockIdLoading = true;
		},
		setFireblockIdSuccess: (state) => {
			const newState = state;
			newState.setFireblockIdLoading = false;
		},

		checkFireblockId: (state, action: PayloadAction<ICheckFireblockIdRequest>) => {},
		putUserChangeReferralRequest: (state, action: PayloadAction<IUserReferralRequestPayload>) => {},
		getUsersFileRequest: (state, action?: PayloadAction<IGetFilePayload>) => {},
		setUserAdmin: (state, { payload }: PayloadAction<boolean>) => {
			const newState = state;
			newState.mailSwitcher = payload;
		},
		generateSecretForZip: (state, action: PayloadAction<number>) => {},
		resetAllLoaders: (state) => {
			const newState = state;
			newState.loader = false;
			newState.oneUserLoading = false;
			newState.createUserLoader = false;
			newState.requiredEmailsLoading = false;
			newState.adminPasswordResetRequestsLoading = false;
			newState.oneUserFeesLoading = false;
			newState.oneUserFeesSpreadLoading = false;
			newState.referralSystemLoading = false;
			newState.setFireblockIdLoading = false;
		},
		updateBalancesRequest: (state, action: PayloadAction<IUpdateBalancesPayload>) => {
			const newState = state;
			newState.loader = true;
		},
		updateBalancesSuccess: (state) => {
			const newState = state;
			newState.loader = false;
		},
		disableLoader: (state) => {
			const newState = state;
			newState.loader = false;
		},

		downloadFileRequest: (state, { payload }: PayloadAction<IUserFileDownloadPayload>) => {
			const newState = state;
			newState.fileLoading = true;
		},
	},
});

export default users.reducer;
export const {
	getUsersInit,
	getUsersRequest,
	getUsersSuccess,
	updateUserBlockStatus,
	getOneUserRequest,
	getOneUserSuccess,
	getOneUserError,
	getUserWalletsRequest,
	getUserWalletsSuccess,
	getUserReferralRequest,
	getUserReferralSuccess,
	putUserLiquidityProviderRequest,
	putUserLiquidityProviderSuccess,
	getUserTradingLimitsRequest,
	getUserTradingLimitsSuccess,
	putUserTradingLimitRequest,
	deleteUserReferralRequest,
	updateUserArchiveStatus,
	createUserRequest,
	createUserRequestFinished,
	getResetRequests,
	getResetRequestsSuccess,
	reset2FA,
	reject2FA,
	getUserRequiredEmailsRequest,
	getUserRequiredEmailsSuccess,
	putUserRequiredEmailsRequest,
	setToggler,
	getAdminRequiredEmailsRequest,
	getAdminRequiredEmailsSuccess,
	putAdminRequiredEmailsRequest,
	setAdminToggler,
	updateUserDataRequest,
	getAdminResetDataRequest,
	getAdminResetPasswordRequestsSuccess,
	getAdminResetTwoFaDataRequest,
	rejectAdmin2FA,
	resetAdmin2FA,
	rejectAdminPassword,
	resetAdminPassword,
	rejectUserPassword,
	resetUserPassword,
	getUserFeeRequest,
	getUserFeeFiatRequest,
	getUserFeeFiatSuccess,
	updateUserFiatFees,
	getUserFeeSuccess,
	putUserFeesRequest,
	updateUserFees,
	getUserFeeUpdateRequest,
	getUserFeeFiatUpdateRequest,
	getUserSpreadFeeRequest,
	getUserFeeSpreadSuccess,
	updateUserSpreadFeesRequest,
	putUserSpreadFeesRequest,
	updateUserSpreadFees,
	getAdminResetTwoFaDataSuccess,
	getUserPasswordRequestsSuccess,
	getUserPasswordResetRequests,
	setUserData,
	putUserChangeReferralRequest,
	setUserStatus,
	setUserStatusSuccess,
	setFireblockIdRequest,
	setFireblockIdSuccess,
	getUsersFileRequest,
	setUserAdmin,
	generateSecretForZip,
	referralSystemRequest,
	referralSystemSuccess,
	checkFireblockId,
	resetAllLoaders,
	updateBalancesRequest,
	updateBalancesSuccess,
	getUserTransferLimitsRequest,
	getUserTransferLimitsSuccess,
	putUserTransferLimitRequest,
	disableLoader,
	downloadFileRequest,
} = users.actions;
