import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import Popup from 'reactjs-popup';
import { Field, Form, Formik } from 'formik';
import cn from 'classnames';
import { getCurrencyData } from '../../../redux/reducers/currency/selectors';
import { ICurrencySelectItem } from '../../../ui/Formik/Select/CurrencySelect/types';
import { clearError } from '../../../redux/reducers/errors/reducer';
import SelectDate from '../../../ui/SelectDate';
import CurrencySelect from '../../../ui/Formik/Select/CurrencySelect';
import Input from '../../../ui/Formik/Input';
import InputTotp from '../../../ui/Formik/InputTotp';
import { IPopUp } from '../types';
import UserSearch from './UserSearch/UserSearch';
import { ICurrencyDataItem } from '../../../redux/reducers/currency/types';
import { addTradeRequest } from '../../../redux/reducers/transactions/reducer';
import { IAddTradeWorker } from '../../../redux/reducers/transactions/types';
import SuccessNewMemberPopUp from '../SuccessNewMemberPopUp/SuccessNewMemberPopUp';
import { popUpOpen, setPopUpData } from '../../../redux/reducers/popUp/reducer';

export interface IAddTradeForm {
	account_name: string;
	email: string;
	full_name: string;
	selectedOptions: [];
}

const AddNewMember: FC<IPopUp> = ({ open, closeModal, buttonClick }) => {
	const dispatch = useDispatch();
	const currencyData = useSelector(getCurrencyData);

	const [reset, setReset] = useState(false);
	const [userId, setUserId] = useState('');

	// Currencies
	const [fromCurrencies, setFromCurrencies] = useState<ICurrencyDataItem[]>(currencyData);
	const [selectedFromCurrency, setSelectedFromCurrency] = useState<ICurrencySelectItem | null>(
		null,
	);
	const [toCurrencies, setToCurrencies] = useState<ICurrencyDataItem[]>(currencyData);
	const [selectedToCurrency, setSelectedToCurrency] = useState<ICurrencySelectItem | null>(null);

	const handleGetAssetIdByCode = (currentCode: string) => {
		return currencyData.filter(({ code }) => code === currentCode.toLowerCase())[0].id;
	};

	const handleChangeUserId = (currentUserId: string) => {
		setUserId(currentUserId);
	};

	const handleFromCurrencyChange = (value: ICurrencySelectItem) => {
		setSelectedFromCurrency(value);
		if (value.id === selectedToCurrency?.id) {
			setSelectedToCurrency(null);
		}
		setToCurrencies(currencyData?.filter(({ id }) => id !== value.id));
	};
	const handleToCurrencyChange = (value: ICurrencySelectItem) => {
		setSelectedToCurrency(value);
		if (value.id === selectedFromCurrency?.id) {
			setSelectedFromCurrency(null);
		}
	};

	const handleCloseModal = () => {
		setSelectedToCurrency(null);
		setSelectedFromCurrency(null);
		closeModal();
	};

	const handleCloseModalClick = () => {
		setSelectedToCurrency(null);
		setSelectedFromCurrency(null);
		closeModal();
	};

	const initialValues: IAddTradeForm = {
		account_name: '',
		email: '',
		full_name: '',
		selectedOptions: [],
	};

	const validationSchema = yup.object().shape({
		account_name: yup
			.string()
			.required('Account name is required')
			.min(3, 'Account name must be at least 3 characters')
			.max(20, 'Account name must be at most 20 characters')
			.matches(
				/^[a-zA-Z0-9_]+$/,
				'Account name can only contain Latin letters, numbers, and underscores',
			),
		email: yup
			.string()
			.email('Invalid email format')
			.required('Email is required')
			.max(50, 'Email must be at most 50 characters'),
		full_name: yup
			.string()
			.required('Full name is required')
			.min(3, 'Full name must be at least 3 characters')
			.max(50, 'Full name must be at most 50 characters')
			.matches(/^[a-zA-Z\s]+$/, 'Full name can only contain Latin letters and spaces'),
		// eslint-disable-next-line react/forbid-prop-types
		selectedOptions: yup.array().min(1, 'Select at least one role').required(),
	});

	useEffect(() => {
		setFromCurrencies(currencyData);
		setToCurrencies(currencyData);
	}, [currencyData, open]);

	return (
		<>
			<Popup open={open} nested onClose={handleCloseModalClick}>
				<div className="popup popup--width-480">
					{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
					<button type="button" className="popup__close-btn" onClick={handleCloseModalClick} />
					<div className="popup-header popup-header--mb-20">
						<p className="popup-header__title">Add New Member</p>
					</div>
					<Formik
						validateOnBlur
						enableReinitialize
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={(values: IAddTradeForm, { resetForm, setSubmitting }) => {
							const params = {
								account_name: values.account_name,
								email: values.email,
								full_name: values.full_name,
								userId,
							};
							console.log(params);
							// Dispatch your action here if needed
							// dispatch(addTradeRequest(params));
							setSubmitting(false);
							resetForm();
							handleCloseModal();
							setTimeout(() => {
								dispatch(popUpOpen('SuccessNewMemberPopUp'));
								dispatch(
									setPopUpData({
										title: 'Add New Member',
										message: 'New user was added to Alma Edis EUR',
									}),
								);
							});
						}}
					>
						{({ isSubmitting, isValid, dirty, errors, touched }) => (
							<Form>
								<div className="popup-body popup-body--transaction">
									<div className="phone-number-input-wrap">
										<Field
											required
											type="text"
											placeholder="Enter Account Name"
											component={Input}
											name="account_name"
											title="Account Name (title)"
										/>
										<UserSearch
											handleChangeUserId={handleChangeUserId}
											resetForm={reset}
											handleResetForm={() => setReset(false)}
											title="Search User by"
											style={{ marginBottom: '10px' }}
										/>
										<Field
											required
											type="email"
											placeholder="Enter Email"
											component={Input}
											name="email"
											title="Email"
										/>
										<Field
											required
											type="text"
											placeholder="Enter Full Name"
											component={Input}
											name="full_name"
											title="Full Name"
										/>
										<div className="checkbox-role" role="group" aria-labelledby="checkbox-group">
											<p className="input__name">Role</p>
											<div className="checkbox">
												<label>
													<Field type="checkbox" name="selectedOptions" value="Viewer" />
													<span className="custom-checkbox" />
													Viewer
												</label>
												<label>
													<Field type="checkbox" name="selectedOptions" value="Trader" />
													<span className="custom-checkbox" />
													Trader
												</label>
												<label>
													<Field type="checkbox" name="selectedOptions" value="Initiator" />
													<span className="custom-checkbox" />
													Initiator
												</label>
												<label>
													<Field type="checkbox" name="selectedOptions" value="Approver" />
													<span className="custom-checkbox" />
													Approver
												</label>
											</div>
										</div>
									</div>
								</div>
								<div className="popup-footer">
									<div className="popup-submit popup-submit--column">
										<button
											type="submit"
											className="btn btn-primary btn--full"
											disabled={!(isValid && dirty) || isSubmitting || !userId}
										>
											Submit
										</button>
										<button
											type="button"
											onClick={handleCloseModal}
											className="btn btn-danger btn--full"
										>
											Cancel
										</button>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</Popup>
		</>
	);
};

export default AddNewMember;
