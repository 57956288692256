import React, { useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from 'ui/Pagination/Pagination';
import PerPageSelect from 'ui/PerPageSelect/PerPageSelect';
import {
	getUserFeeFiatRequest,
	getUserFeeRequest,
	putUserFeesRequest,
	updateUserFees,
	updateUserFiatFees,
} from 'redux/reducers/users/reducer';
import {
	getUsersFees,
	getUsersFeesFiatLoader,
	getUsersFeesLoader,
	getUsersFiatFees,
} from 'redux/reducers/users/selectors';
import { IUserFeesUpdteBody, IUserFeesData } from 'redux/reducers/users/types';
import ReactPaginate from 'react-paginate';
import IconSvg from 'ui/Svg/IconSvg';
import FeeManagementFiatFiatTradeItem from './FeeManagementTradeItem/FeeManagementFiatFiatTradeItem';

import { IAccountDetailsParams } from '../../types';
import FeeManagementDepositItem from '../FeeManagementDeposit/FeeManagementDepositItem/FeeManagementDepositItem';
import TableBodyNoData from '../../../../ui/TableBodyNoData/TableBodyNoData';
import Loading from '../../../../layouts-elements/Loading/Loading';

const FeeManagementFiatFiatTrade = ({ permission }: { permission: boolean }) => {
	const dispatch = useDispatch();
	const { userId } = useParams<IAccountDetailsParams>();
	const usersFeesFiat = useSelector(getUsersFiatFees);
	const loading = useSelector(getUsersFeesFiatLoader);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState<number | undefined>(10);
	const handleSetPerPage = (value: number | undefined) => {
		setPerPage(value);
		setCurrentPage(1);
	};
	const pageCount = usersFeesFiat?.last_page || 1;
	useLayoutEffect(() => {
		if (userId) {
			const params: IUserFeesData = {
				id: userId,
				current_page: currentPage,
				per_page: perPage,
				type: 'fiat',
			};
			dispatch(getUserFeeFiatRequest(params));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, userId, perPage, currentPage]);

	const updateHandler = (obj: IUserFeesUpdteBody) => {
		dispatch(
			putUserFeesRequest({
				id: userId,
				body: obj,
				current_page: currentPage,
				per_page: perPage,
				feeType: 'Trade',
			}),
		);
		dispatch(updateUserFiatFees(obj));
	};

	return (
		<>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table--user-management-fee-management-withdraw-crypto">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<p>Currency</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Fee %</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Fee Fixed</p>
									</div>
								</div>
								<div className="td td--right">
									<div className="td-name">
										<p>Action</p>
									</div>
								</div>
							</div>
						</div>
						<div className="table-body">
							{!loading && (
								<>
									{' '}
									{usersFeesFiat?.data?.length ? (
										usersFeesFiat.data
											.filter((el: any) => el.type === 'fiat' || el.type === 'token')
											.map((el: any) => (
												<FeeManagementFiatFiatTradeItem
													key={el.id}
													updateHandler={updateHandler}
													el={el}
													permission={permission}
												/>
											))
									) : (
										<TableBodyNoData />
									)}
								</>
							)}
							{loading ? <Loading /> : null}
						</div>
						{/* {!!usersFees?.data.length && ( */}
						{/*	<div className="table-footer"> */}
						{/*		<div className="pagination-block"> */}
						{/*			<PerPageSelect onChange={handleSetPerPage} /> */}
						{/*			{pageCount > 1 && ( */}
						{/*				<Pagination */}
						{/*					pageCount={pageCount} */}
						{/*					currentPage={currentPage} */}
						{/*					onPageChange={setCurrentPage} */}
						{/*				/> */}
						{/*			)} */}
						{/*		</div> */}
						{/*	</div> */}
						{/* )} */}
					</div>
				</div>
			</div>
			{/* {!!usersFees?.data.length && (
				<div className="table-pagination">
					<PerPageSelect onChange={handleSetPerPage} />
					{pageCount > 1 && (
						<div className="pagination-block">
							<Pagination
								pageCount={pageCount}
								currentPage={currentPage}
								onPageChange={setCurrentPage}
							/>
						</div>
					)}
				</div>
			)} */}
		</>
	);
};

export default FeeManagementFiatFiatTrade;
