import * as yup from 'yup';
import { Field, Form, Formik } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import handleUploadIcon from 'ui/Formik/InputFile/config';
import Input from '../../../ui/Formik/Input';
import InputFile from '../../../ui/Formik/InputFile/InputFile';
import { IStateLocation } from '../types';
import { CloseFileIcon } from '../../../assets/inline-svg';

import { getOneUser } from '../../../redux/reducers/users/selectors';
import { getCurrencyData } from '../../../redux/reducers/currency/selectors';
import { ICurrencyDataItem } from '../../../redux/reducers/currency/types';
import { getFiatDepositsList } from '../../../redux/reducers/transactions/selectors';
import DefaultFields from '../DefaultFields/DefaultFields';
import { roundingNumber } from '../../../services/utils/roundingNumber';
import {
	createFiatDetailsRequest,
	deleteFiatDetailsFilesRequest,
	downloadFiatFileRequest,
} from '../../../redux/reducers/detailedView/reducer';

interface IDetailedDepositFiatForm {
	item: any;
}

interface ICustomInput {
	label: string;
	value: string | number | undefined;
	type: string;
	disabled: boolean;
}

const CustomInput: FC<ICustomInput> = ({ label, value, type, disabled }) => {
	return (
		<div className="input">
			<label>
				<p className="input__name">{label}</p>
				<div className="input-wrapper">
					<input type={type} className="input-item" value={value} disabled={disabled} />
				</div>
			</label>
		</div>
	);
};

const DetailedDepositFiatForm: FC<IDetailedDepositFiatForm> = ({ item }) => {
	const dispatch = useDispatch();
	const userData = useSelector(getOneUser);
	const currencyList = useSelector(getCurrencyData);
	const [asset, setAsset] = useState<ICurrencyDataItem | null>(null);
	const fiatTransactions = useSelector(getFiatDepositsList);
	const [fileList, setFileList] = useState<any>([]);
	const { state } = useLocation<IStateLocation>();

	const initialValues = {
		client_notes: item.client_notes || '',
		reference: item.reference || item.tx_hash || '-',
		description:
			item.description ||
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			`${item.admin_requisite?.bank_name} ${item.admin_requisite?.account_number}` ||
			'-',
		destination:
			item.admin_requisite?.bank_name && item.admin_requisite?.account_number
				? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				  `${item.admin_requisite?.bank_name} ${item.admin_requisite?.account_number}`
				: '-',
		admin_notes: item.admin_notes || '',
	};

	const validationSchema = yup.object().shape({
		admin_notes: yup.string().max(255, 'Maximum length is 255 characters'),
		client_notes: yup.string().max(255, 'Maximum length is 255 characters'),
		reference: yup.string().max(255, 'Maximum length is 255 characters'),
		description: yup.string().max(255, 'Maximum length is 255 characters'),
	});

	const deleteFilesHandler = (
		event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
		file: string,
	) => {
		event.stopPropagation();
		const payload = {
			id: item.id,
			files: [file],
		};
		dispatch(deleteFiatDetailsFilesRequest(payload));
		// console.log(payload);
	};

	useEffect(() => {
		item && setAsset(currencyList.filter((el) => el.id === item?.asset_id)[0]);
	}, [currencyList, item]);

	const handleDownloadFile = (file: string) => {
		dispatch(downloadFiatFileRequest({ file: file.split('/')[0] }));
	};

	return (
		<div className="detailed-view-form">
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={(value, { resetForm, setSubmitting }) => {
					setSubmitting(true);
					const formData = new FormData();
					fileList &&
						fileList.length &&
						fileList.forEach((file: File) => formData.append(`files[]`, file));
					const requestBody = {
						id: item.id,
						client_notes: value.client_notes,
						admin_notes: value.admin_notes,
						reference: value.reference,
						description: value.description,
					};
					Object.keys(requestBody).forEach((key) => {
						const valueRequest = requestBody[key as keyof typeof requestBody];
						if (valueRequest !== undefined) {
							formData.append(key, valueRequest.toString());
						}
					});
					dispatch(
						createFiatDetailsRequest({
							id: item.id,
							body: formData,
						}),
					);
					setFileList([]);
					resetForm();
					setSubmitting(false);
				}}
				enableReinitialize
				validateOnBlur
			>
				{({
					setFieldTouched,
					touched,
					values,
					errors,
					isValid,
					dirty,
					setFieldValue,
					isSubmitting,
					setTouched,
				}) => (
					<>
						<Form>
							<div className="input-wrap--col-2">
								{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
								{/* @ts-ignore */}
								<DefaultFields userData={userData} item={item} />
								<CustomInput
									label="Currency"
									// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									value={`${asset?.code.toUpperCase()} ${asset?.name}`}
									type="text"
									disabled
								/>
								<CustomInput
									label="Gross"
									value={`${roundingNumber(
										item.gross_fee,
										asset?.code,
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									)} ${asset?.code.toUpperCase()}`}
									type="text"
									disabled
								/>
								<CustomInput
									label="Fee"
									value={`${roundingNumber(
										item.fee,
										asset?.code,
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									)} ${asset?.code.toUpperCase()}`}
									type="text"
									disabled
								/>
								<CustomInput
									label="Net"
									value={`${roundingNumber(
										item.net_fee,
										asset?.code,
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									)} ${asset?.code.toUpperCase()}`}
									type="text"
									disabled
								/>
								<Field
									title="Destination"
									type="text"
									placeholder=""
									name="destination"
									component={Input}
									disable
								/>
								<CustomInput label="Tx ID" value={item.id} type="text" disabled />
								<CustomInput label="Oneify ID" value={item?.user?.oneify_id} type="text" disabled />
								<CustomInput
									label="Status"
									// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									value={`${item.status.charAt(0).toUpperCase()}${item.status.slice(1)}`}
									type="text"
									disabled
								/>
								<Field
									title="Description"
									type="text"
									placeholder=""
									name="description"
									component={Input}
									onBlur={() => setFieldTouched('description')}
								/>
								<Field
									title="Reference"
									type="text"
									placeholder=""
									name="reference"
									component={Input}
									onBlur={() => setFieldTouched('reference')}
								/>
							</div>
							<Field
								title="Admin Notes"
								type="textarea"
								placeholder=""
								name="admin_notes"
								component={Input}
								onBlur={() => setFieldTouched('admin_notes')}
							/>
							<Field
								title="Client Notes"
								type="textarea"
								placeholder=""
								name="client_notes"
								component={Input}
								onBlur={() => setFieldTouched('client_notes')}
							/>
							<div className="input">
								<p className="input__name">Upload File</p>
								<InputFile fileList={fileList} setFileList={setFileList} />
							</div>
							<div className="input">
								<p className="input__name">Attached Files</p>
								<div className="input__links">
									{item.files?.length > 0 ? (
										item.files.map((file: string, idx: number) => {
											return (
												<div
													key={`${file}-${idx.toString()}`}
													className="input-file-preview__item"
													onClick={() => handleDownloadFile(file)}
												>
													<img src={handleUploadIcon(file.split('.')[1])} alt="" />
													<span className="input-file-preview__name" title={file}>
														{file}
													</span>
													<span
														className="input-file-preview__item__del"
														onClick={(event) => deleteFilesHandler(event, file)}
													>
														<CloseFileIcon />
													</span>
												</div>
											);
										})
									) : (
										<div> No data</div>
									)}
								</div>
							</div>
							<button
								type="submit"
								className="btn btn-primary btn--full"
								style={{ marginTop: 14 }}
								disabled={isSubmitting}
							>
								Submit
							</button>
						</Form>
					</>
				)}
			</Formik>
		</div>
	);
};

export default DetailedDepositFiatForm;
