// eslint-disable import/namespace
import React, { FC, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import IconSvg from 'ui/Svg/IconSvg';
import { getBankAccountsWithSelectFilters } from 'redux/reducers/transactionsFilters/selectors';
import { addFilter, deleteFilter, resetFilters } from 'redux/reducers/transactionsFilters/reducer';
import { EFiltersType, ETransactionsFilters } from 'redux/reducers/transactionsFilters/types';
import { convertFilterObjectToParamsObject } from 'services/utils/convertFilterObjectToParamsObject';
import { IApiGetTransactionParams } from 'services/api/transactions/types';
import TableBodyNoData from 'ui/TableBodyNoData/TableBodyNoData';
import PerPageSelect from 'ui/PerPageSelect/PerPageSelect';
import SearchWithSelect, { ISearchSelectArr } from 'ui/SearchWithSelect/SearchWithSelect';
import ActiveFilters from 'ui/ActiveFilters/ActiveFilters';
import { getDepositAccounts, getUserDepositAccounts } from 'redux/reducers/walletBalance/selectors';
import { activateResetActiveBankAccounts } from 'redux/reducers/bankAccounts/reducer';
import {
	getDepositAccountRequest,
	getUserDepositAccountRequest,
} from 'redux/reducers/walletBalance/reducer';
import { currencyDataRequest } from 'redux/reducers/currency/reducer';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import { navListAfterLogin } from '../../../../routes/routesList';
import Loading from '../../../../layouts-elements/Loading/Loading';
import { popUpOpen } from '../../../../redux/reducers/popUp/reducer';

const FiltersSearchArray = [{ name: 'Full Name', value: ETransactionsFilters.FULL_NAME }];

export interface IBankAccountsWithSelectProps {
	userId?: number;
	resetStates?: boolean;
	withoutAction?: boolean;
	editMode?: boolean;
}

const AssociatedAccountsMain: FC<IBankAccountsWithSelectProps> = ({
	userId,
	resetStates,
	withoutAction,
	editMode,
}) => {
	const dispatch = useDispatch();
	const bankAccounts = useSelector(userId ? getUserDepositAccounts : getDepositAccounts);
	const activeFilters = useSelector(getBankAccountsWithSelectFilters);

	const [currentPage, setCurrentPage] = useState(bankAccounts?.current_page || 1);
	const [resetSearchValue, setResetSearchValue] = useState(false);
	const [perPage, setPerPage] = useState<number | undefined>(10);
	const handleSetPerPage = (value: number | undefined) => {
		setPerPage(value || bankAccounts?.total);
		setCurrentPage(1);
	};

	const handlePageClick = ({ selected }: { selected: number }) => {
		setCurrentPage(selected + 1);
	};

	const pageCount = bankAccounts?.last_page ? bankAccounts.last_page : 1;

	useLayoutEffect(() => {
		if (currentPage > pageCount) {
			setCurrentPage(1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bankAccounts?.last_page]);

	useLayoutEffect(() => {
		dispatch(currencyDataRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useLayoutEffect(() => {
		setPerPage(10);
		setCurrentPage(1);
		setResetSearchValue(false);
		dispatch(resetFilters(EFiltersType.BANK_ACCOUNTS_WITH_SELECT));
		dispatch(activateResetActiveBankAccounts());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId, resetStates]);

	useLayoutEffect(() => {
		const filterParams = convertFilterObjectToParamsObject(activeFilters);

		const params: IApiGetTransactionParams = {
			per_page: perPage,
			current_page: currentPage,
			...filterParams,
		};

		!userId && dispatch(getDepositAccountRequest(params));
		!!userId && dispatch(getUserDepositAccountRequest({ userId, params }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeFilters, perPage, currentPage, userId]);

	const handleResetSearchValue = () => {
		setResetSearchValue(!resetSearchValue);
	};

	const handleDeleteFilter = (filterType: ETransactionsFilters) => {
		handleResetSearchValue();
		dispatch(
			deleteFilter({ type: filterType, filterType: EFiltersType.BANK_ACCOUNTS_WITH_SELECT }),
		);
	};

	const handleResetFilters = () => {
		handleResetSearchValue();
		dispatch(resetFilters(EFiltersType.BANK_ACCOUNTS_WITH_SELECT));
	};

	const handleSearch = (searchField: ISearchSelectArr, currentSearchValue: string) => {
		if (currentSearchValue === '') {
			dispatch(
				deleteFilter({
					type: searchField.value as ETransactionsFilters,
					filterType: EFiltersType.BANK_ACCOUNTS_WITH_SELECT,
				}),
			);
		} else {
			dispatch(
				addFilter({
					type: searchField.value as ETransactionsFilters,
					value: currentSearchValue,
					name: `${searchField.name}: ${currentSearchValue}`,
					filterType: EFiltersType.BANK_ACCOUNTS_WITH_SELECT,
				}),
			);
		}
	};
	const users = [
		{
			addedAt: '2022-10-12',
			fullName: 'Cody Fisher',
			email: 'alma.lawson@example.com',
			companyName: 'Binford Ltd.',
			roles: ['Admin'],
		},
		{
			addedAt: 'Pending',
			fullName: 'Jenny Wilson',
			email: 'georgia.young@example.com',
			companyName: 'Acme Co.',
			roles: ['Viewer', 'Initiator', 'Trader'],
		},
		{
			addedAt: '2022-10-12',
			fullName: 'Brooklyn Simmons',
			email: 'alma.lawson@example.com',
			companyName: 'Big Kahuna Burger Ltd.',
			roles: ['Approver'],
		},
		{
			addedAt: '2022-10-12',
			fullName: 'Jacob Jones',
			email: 'jessica.hanson@example.com',
			companyName: 'Barone LLC.',
			roles: ['Initiator', 'Trader'],
		},
		{
			addedAt: '2022-10-12',
			fullName: 'Leslie Alexander',
			email: 'dolores.chambers@example.com',
			companyName: 'Biffco Enterprises Ltd.',
			roles: ['Trader'],
		},
		{
			addedAt: '2022-10-12',
			fullName: 'Arlene McCoy',
			email: 'nevaeh.simmons@example.com',
			companyName: 'Abstergo Ltd.',
			roles: ['Trader'],
		},
	];

	const history = useHistory();
	const addPopUp = () => {
		dispatch(popUpOpen('AddNewMember'));
	};
	return (
		<>
			<div className="associated-accounts_main_title_main">
				<div
					onClick={() => {
						history.goBack();
					}}
					className="back-btn"
				>
					{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
					<button type="button" className="wallet-operations-header__back" />
				</div>
				<p>Alma Edis EUR</p>
			</div>
			<div className="associated-accounts_main">
				<div className="associated-accounts_main__title">
					<p>Members</p>
				</div>
				<div className="filters__search-block filters__search-block--bank-accounts-with-select">
					<SearchWithSelect
						resetValue={resetSearchValue}
						selectArr={FiltersSearchArray}
						handleSearch={handleSearch}
						isButton
						isButtonFuc={addPopUp}
						handleReset={handleResetFilters}
					/>
				</div>
				<div className="table-block">
					<div className="table-wrapper ">
						<div className="table table--associated-accounts_main">
							<div className="table-header">
								<TableHeader />
							</div>
							<div className="table-body">
								{users ? (
									users?.map((item: any) => <TableBody key={item.id} item={item} />)
								) : (
									<TableBodyNoData />
								)}
							</div>
						</div>
					</div>
				</div>

				{!!bankAccounts?.data.length && (
					<div className="table-footer table-footer--bank-accounts-with-select">
						<div className="pagination-block pagination-block--full">
							<PerPageSelect onChange={handleSetPerPage} />
							{pageCount > 1 && (
								<ReactPaginate
									breakLabel="..."
									pageCount={1}
									// onPageChange={handlePageClick}
									forcePage={currentPage - 1}
									className="pagination"
									activeClassName="active"
									previousClassName="arrow arrow--prev"
									nextClassName="arrow arrow--next"
									previousLabel={<IconSvg name="keyboard-arrow-left" w="10" h="16" />}
									nextLabel={<IconSvg name="keyboard-arrow-right" w="10" h="16" />}
								/>
							)}
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default AssociatedAccountsMain;
