import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

type User = {
	addedAt: string;
	addedAtMini: string;
	fullName: string;
	email: string;
	companyName: string;
	roles: string[];
};

// Define the props for TableBody component
interface IProps {
	item: User;
}

const TableBody: FC<IProps> = ({ item }) => {
	const history = useHistory();

	return (
		<div className="tr">
			<div className="td">
				<div className="td-hidden-name">Added at</div>
				<div className="td__wrap">
					<div className="td__text">
						{item.addedAt}
						<span>16:47:53</span>
					</div>
				</div>
			</div>
			<div className="td">
				<div className="td-hidden-name">Full Name</div>
				<div className="td__wrap">
					<div className="td__text">{item.fullName}</div>
				</div>
			</div>
			<div className="td">
				<div className="td-hidden-name">Email</div>
				<div className="td__wrap">
					<div className="td__text">
						<a href="!#">{item.email}</a>
					</div>
				</div>
			</div>
			<div className="td">
				<div className="td-hidden-name">Company Name</div>
				<div className="td__wrap">
					<div className="td__text">{item.companyName}</div>
				</div>
			</div>
			<div className="td">
				<div className="td-hidden-name">Roles</div>
				<div className="td__wrap">
					<div className="td__text">{item.roles.join(', ')}</div>
				</div>
			</div>
		</div>
	);
};

export default TableBody;
