export type IRoundingNumber = (
	number?: number | string,
	value?: string,
	options?: {
		toFixedAll?: number;
		toFixedValue?: number;
		cryptoCurrency?: string[];
	},
) => string;

export const roundingNumber: IRoundingNumber = (number, value, options) => {
	// eslint-disable-next-line no-restricted-globals
	if (number === null || number === undefined || number === '' || isNaN(Number(number))) {
		return '-';
	}

	const fiatZero = 2;
	const cryptoZero = 5;
	let roundingNumberFilter = '';
	const currentValue = value || '';
	const toFixedAll = options?.toFixedAll ?? fiatZero;
	const toFixedValue = options?.toFixedValue ?? cryptoZero;
	const cryptoCurrency = options?.cryptoCurrency ?? [
		'BTC',
		'LTC',
		'BCH',
		'ETH',
		'TRX',
		'XRP',
		'crypto',
	];
	let decimalPlaces = toFixedAll;

	cryptoCurrency.forEach((currency) => {
		if (currency.toLowerCase() === currentValue.toLowerCase()) {
			decimalPlaces = toFixedValue;
		}
	});

	const num = Number(number);
	const isCrypto = decimalPlaces === toFixedValue;

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const truncateNumber = (value: number, decimals: number): string => {
		// eslint-disable-next-line no-restricted-globals
		if (isNaN(value)) return '-';

		// Truncate using a string approach
		const [integerPart, fractionalPart = ''] = value.toString().split('.');
		const truncatedFraction = fractionalPart.slice(0, decimals); // truncate fractional part
		return decimals > 0 ? `${integerPart}.${truncatedFraction}` : integerPart;
	};

	// Truncate number to specified decimal places
	roundingNumberFilter = truncateNumber(num, decimalPlaces);

	// Remove trailing zeros from truncated fraction (if any) to mimic old behavior
	roundingNumberFilter = roundingNumberFilter.replace(/(\.\d*?)0+$/, '$1');

	// Ensure that if currency is fiat and the number is an integer, it has two decimals (like 100.00).
	// This aligns with original behavior where fiat had exact decimal places even for integers.
	if (!isCrypto && !roundingNumberFilter.includes('.')) {
		roundingNumberFilter += `.${'0'.repeat(toFixedAll)}`;
	}

	// If crypto, we ensure exactly 'decimalPlaces' decimals (like 100.00000).
	if (isCrypto) {
		const [intPart, fracPart = ''] = roundingNumberFilter.split('.');
		roundingNumberFilter = `${intPart}.${fracPart.padEnd(decimalPlaces, '0')}`;
	}

	// Format number with thousand separators
	const distanceNumber = roundingNumberFilter
		.replace(/(\d+(\.\d+[1-9])?)(\.?0+$)/, '$1') // remove trailing zeros in fractional part if it's non-significant
		.replace(/\B(?=(\d{3})+(?!\d))/g, ' '); // insert spaces for thousand separators

	// Separate integer and decimal parts for further formatting
	const lengthDelete = distanceNumber.indexOf('.');
	const arrStart = lengthDelete === -1 ? distanceNumber : distanceNumber.slice(0, lengthDelete);
	const arrEnd = lengthDelete === -1 ? '' : distanceNumber.slice(lengthDelete).replace(/\s+/g, '');
	let numberFix = arrStart + arrEnd;

	// The following logic ensures the correct number of decimal places for fiat and crypto:
	const numberFixArrayReverse = numberFix.split('').reverse();

	// For fiat, ensure exactly 'toFixedAll' decimals.
	if (!isCrypto) {
		const dotIndex = numberFix.indexOf('.');
		const decimalsCount = dotIndex !== -1 ? numberFix.length - dotIndex - 1 : 0;
		if (dotIndex === -1) {
			// If no decimal point, add one followed by zeros
			numberFix += `.${'0'.repeat(toFixedAll)}`;
		} else if (decimalsCount < toFixedAll) {
			// If decimal part is shorter, pad with zeros
			numberFix += '0'.repeat(toFixedAll - decimalsCount);
		}
	}

	if (isCrypto) {
		const dotIndex = numberFix.indexOf('.');
		const decimalsCount = dotIndex !== -1 ? numberFix.length - dotIndex - 1 : 0;
		if (dotIndex === -1) {
			numberFix += `.${'0'.repeat(toFixedValue)}`;
		} else if (decimalsCount < toFixedValue) {
			numberFix += '0'.repeat(toFixedValue - decimalsCount);
		} else if (decimalsCount > toFixedValue) {
			numberFix = numberFix.slice(0, dotIndex + 1 + toFixedValue);
		}
	}

	if (numberFix.toString() === 'NaN' || numberFix.toString() === 'undefined') {
		numberFix = '-';
	}

	numberFix = numberFix.replace(/ /g, ',');

	return numberFix;
};
