import LoginPage from 'pages/LoginPage/LoginPage';
import ResetPasswordPage from 'pages/ResetPasswordPage/ResetPasswordPage';
import UserManagementPage from 'pages/Auth/UserManagementPage/UserManagementPage';
import AccountDetailsPage from 'pages/Auth/AccountDetailsPage/AccountDetailsPage';
import ReferralSystemPage from 'pages/Auth/ReferralSystemPage/ReferralSystemPage';
import FeManagementPage from 'pages/Auth/FeManagementPage/AccountDetailsPage';
import CreateUserPage from 'components/tables/CreateUserTables';
import ResetRequestPage from 'pages/Dashboard/TwoFAPage';
import RequiredEmailsPage from 'pages/Dashboard/RequiredEmails';
import TransfersAndTradeReportsPage from 'pages/Auth/TransfersAndTradeReportsPage/TransfersAndTradeReportsPage';
import AdminPanelPage from 'pages/Auth/AdminPanelPage/AdminPanelPage';
import DepositAccountsPage from 'pages/Auth/DepositAccounts/DepositAccountsPage';
import CreateBankAccount from 'components/tables/CreateBankAccount';
import AdminManagementPage from 'pages/Auth/AdminManagementPage/AdminManagementPage';
import CreateAdminPage from 'pages/Auth/AdminManagementPage/CreateNewAdminPage';
import ResetTwoFaPage from 'pages/ResetTwoFaPage/ResetTwoFaPage';
import LoginFirstPage from 'pages/LoginFirstPage/LoginFirstPage';
import LoginFirstTwoFaPage from 'pages/LoginFirstTwoFaPage/LoginFirstTwoFaPage';
import CreateNewTwoFaPage from 'pages/CreateNewTwoFaPage/CreateNewTwoFaPage';
import CreateNewPasswordPage from 'pages/Auth/CreateNewPasswordPage/CreateNewPasswordPage';
import MessagesPage from 'pages/Auth/MessagesPage/MessagesPage';
import { EPermissionNames } from '../redux/reducers/auth/types';
import { IRouteItem, IRouteObject } from './types';
import { ROUTES } from './constants';
import WalletBalancesPage from '../pages/Auth/WalletBalancesPage/WalletBalancesPage';
import TermsOfUse from '../components/TermsUse/TermsUse';
import PrivacyPolicy from '../components/PrivacyPolicy/PrivacyPolicy';
import AssociatedAccountsMain from '../components/tables/CreateUserTables/AssociatedAccountsMain';

// ==========================================:
const navListAfterLogin = {
	home: {
		path: ROUTES.adminPanel.index,
		isNotExact: true,
		component: AdminPanelPage,
		permission: EPermissionNames.DASHBOARD,
		id: 1,
	},
	depositAccounts: {
		path: ROUTES.depositAccounts,
		component: DepositAccountsPage,
		permission: EPermissionNames.DEPOSIT_ACCOUNT,
		id: 3,
	},
	userManagement: {
		path: '/user-management',
		component: UserManagementPage,
		permission: EPermissionNames.USER_MANAGEMENT,
		id: 4,
	},
	accountDetails: {
		path: '/user-management/:userId',
		isNotExact: true,
		component: AccountDetailsPage,
		permission: EPermissionNames.USER_MANAGEMENT,
	},
	//
	feeManagement: {
		path: '/fee-management',
		isNotExact: true,
		component: FeManagementPage,
		permission: EPermissionNames.FEE_MANAGEMENT,
		id: 54,
	},
	feeManagementWithdrawFee: {
		path: '/fee-management/withdraw-fee',
		isNotExact: true,
		component: FeManagementPage,
		permission: EPermissionNames.FEE_MANAGEMENT,
		id: 50,
	},
	feeManagementDepositFee: {
		path: '/fee-management/deposit-fee',
		isNotExact: true,
		component: FeManagementPage,
		permission: EPermissionNames.FEE_MANAGEMENT,
		id: 51,
	},
	feeManagementTradeFee: {
		path: '/fee-management/trade-fee',
		isNotExact: true,
		component: FeManagementPage,
		permission: EPermissionNames.FEE_MANAGEMENT,
		id: 52,
	},
	feeManagementSpreadFee: {
		path: '/fee-management/spread-management-fee',
		isNotExact: true,
		component: FeManagementPage,
		permission: EPermissionNames.FEE_MANAGEMENT,
		id: 53,
	},
	//
	accountMembers: {
		path: '/account/main',
		exact: true,
		component: AssociatedAccountsMain,
		permission: EPermissionNames.WALLET_MANAGEMENT,
		id: 99,
	},
	walletBalances: {
		path: '/wallet-balances',
		isNotExact: true,
		component: WalletBalancesPage,
		permission: EPermissionNames.WALLET_MANAGEMENT,
		id: 8,
	},
	referralSystem: {
		path: '/referral-system',
		isNotExact: true,
		component: ReferralSystemPage,
		permission: EPermissionNames.REFERRAL_MANAGEMENT,
		id: 9,
	},
	//
	transfersAndTradeReportsDepositCrypto: {
		path: ROUTES.transfersAndTradeReports.deposit.index,
		isNotExact: true,
		component: TransfersAndTradeReportsPage,
		permission: EPermissionNames.TRANSFER_TRADE_REPORT,
		id: 40,
	},
	transfersAndTradeReportsWithdrawalsCrypto: {
		path: ROUTES.transfersAndTradeReports.withdrawals.index,
		isNotExact: true,
		component: TransfersAndTradeReportsPage,
		permission: EPermissionNames.TRANSFER_TRADE_REPORT,
		id: 40,
	},
	transfersAndTradeReportsTransfersCrypto: {
		path: ROUTES.transfersAndTradeReports.transfers.index,
		isNotExact: true,
		component: TransfersAndTradeReportsPage,
		permission: EPermissionNames.TRANSFER_TRADE_REPORT,
		id: 40,
	},
	transfersAndTradeReportsTradesCrypto: {
		path: ROUTES.transfersAndTradeReports.trades.index,
		isNotExact: true,
		component: TransfersAndTradeReportsPage,
		permission: EPermissionNames.TRANSFER_TRADE_REPORT,
		id: 40,
	},
	transfersAndTradeReportsLedgerAggregated: {
		path: ROUTES.transfersAndTradeReports.ledgerAggregated.index,
		isNotExact: true,
		component: TransfersAndTradeReportsPage,
		permission: EPermissionNames.TRANSFER_TRADE_REPORT,
		id: 40,
	},
	transfersAndTradeReportsLedger: {
		path: ROUTES.transfersAndTradeReports.ledger.index,
		isNotExact: true,
		component: TransfersAndTradeReportsPage,
		permission: EPermissionNames.TRANSFER_TRADE_REPORT,
		id: 40,
	},
	transfersAndTradeReportsTradesLimits: {
		path: ROUTES.transfersAndTradeReports.tradesLimits.index,
		isNotExact: true,
		component: TransfersAndTradeReportsPage,
		permission: EPermissionNames.TRANSFER_TRADE_REPORT,
		id: 40,
	},
	transfersAndTradeReportsTransfersLimits: {
		path: ROUTES.transfersAndTradeReports.transferLimits.index,
		isNotExact: true,
		component: TransfersAndTradeReportsPage,
		permission: EPermissionNames.TRANSFER_TRADE_REPORT,
		id: 40,
	},
	transfersAndTradeReportsPendingWithdrawal: {
		path: ROUTES.transfersAndTradeReports.pendingWithdrawal.index,
		isNotExact: true,
		component: TransfersAndTradeReportsPage,
		permission: EPermissionNames.TRANSFER_TRADE_REPORT,
		id: 40,
	},
	transfersAndTradeReportsPendingDeposits: {
		path: ROUTES.transfersAndTradeReports.pendingDeposits.index,
		isNotExact: true,
		component: TransfersAndTradeReportsPage,
		permission: EPermissionNames.TRANSFER_TRADE_REPORT,
		id: 40,
	},

	messages: {
		path: ROUTES.messages.index,
		isNotExact: true,
		component: MessagesPage,
		permission: EPermissionNames.MESSAGES,
		id: 11,
	},
	messagesSent: {
		path: ROUTES.messages.sent,
		isNotExact: true,
		component: MessagesPage,
		permission: EPermissionNames.MESSAGES,
		id: 11,
	},
	//
	createUser: {
		path: ROUTES.User.createUser.index,
		isNotExact: true,
		component: CreateUserPage,
		permission: EPermissionNames.USER_MANAGEMENT,
	},
	createBankAccount: {
		path: ROUTES.createBankAccount,
		component: CreateBankAccount,
		permission: EPermissionNames.DEPOSIT_ACCOUNT,
	},
	//
	resetRequestUser2fa: {
		path: ROUTES.UserResetRequest.twofa,
		component: ResetRequestPage,
		permission: EPermissionNames.RESET_REQUEST,
		id: 30,
	},
	resetRequestUserPassword: {
		path: ROUTES.UserResetRequest.password,
		component: ResetRequestPage,
		permission: EPermissionNames.RESET_REQUEST,
		id: 31,
	},

	resetRequestAdmin2fa: {
		path: ROUTES.AdminResetRequest.twofa,
		component: ResetRequestPage,
		permission: EPermissionNames.RESET_REQUEST,
		id: 32,
	},
	resetRequestAdminPassword: {
		path: ROUTES.AdminResetRequest.password,
		component: ResetRequestPage,
		permission: EPermissionNames.RESET_REQUEST,
		id: 33,
	},
	//
	requiredEmails: {
		path: ROUTES.RequiredEmails,
		component: RequiredEmailsPage,
		permission: EPermissionNames.REQUIRED_EMAILS,
		id: 10,
	},
	adminManagementPage: {
		path: ROUTES.adminManagement.index,
		component: AdminManagementPage,
		permission: EPermissionNames.ADMIN_MANAGEMENT,
		id: 2,
	},
	createAdminPage: {
		path: ROUTES.adminManagement.CREATE_ADMIN,
		component: CreateAdminPage,
		permission: EPermissionNames.ADMIN_MANAGEMENT,
	},
	editAdminPage: {
		path: ROUTES.adminManagement.EDIT_ADMIN,
		isNotExact: true,
		component: CreateAdminPage,
		permission: EPermissionNames.ADMIN_MANAGEMENT,
	},
};

const navListBeforeLogin = {
	login: {
		path: '/login',
		component: LoginPage,
	},
	resetPassword: {
		path: '/reset-password',
		component: ResetPasswordPage,
	},
	resetTwoFaPage: {
		path: '/reset-2fa',
		component: ResetTwoFaPage,
	},
	loginFirst: {
		path: '/first_login',
		component: LoginFirstPage,
	},
	loginFirstTwoFa: {
		path: ROUTES.firstLogin2fa,
		component: LoginFirstTwoFaPage,
	},
	createTwoFa: {
		path: ROUTES.CREATE_TWOFA,
		component: CreateNewTwoFaPage,
	},
	createNewPassword: {
		path: ROUTES.CREATE_NEW_PASSWORD,
		component: CreateNewPasswordPage,
	},
	termsOfUse: {
		path: ROUTES.TERMS_OF_USE,
		component: TermsOfUse,
	},
	privacyPolicy: {
		path: ROUTES.PRIVACY_POLICY,
		component: PrivacyPolicy,
	},
};
// ==========================================:

const convertToRoutesArray = (object: IRouteObject): IRouteItem[] => {
	const arr: IRouteItem[] = [];
	Object.keys(object).forEach((item) => arr.push(object[item as keyof typeof object]));
	return arr;
};

const routesListBeforeLogin = convertToRoutesArray(navListBeforeLogin);
const routesListAfterLogin = convertToRoutesArray(navListAfterLogin);

export { navListBeforeLogin, navListAfterLogin, routesListBeforeLogin, routesListAfterLogin };
